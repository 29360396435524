.link {
  width: 50px;
  aspect-ratio: 1 / 1;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #00b1ff;
  border-radius: 50%;

  box-shadow: 0px 0px 3px 2px #fff;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0px 0px 4px 3px #fff;
  }

  img {
    width: 33px;
  }
}
