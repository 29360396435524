.burger {
  position: relative;
  height: 17px;

  &.black {
    & > span {
      background-color: black;
    }
  }

  & > span {
    height: 2px;
    width: 20px;
    background-color: #fff;
    display: block;

    position: absolute;

    transition: transform 0.3s ease, top 0.3s ease, background-color 0.3s ease;

    &:nth-child(1) {
      top: 1px;
    }

    &:nth-child(2) {
      top: 0; bottom: 0;
      margin: auto 0;
    }

    &:nth-child(3) {
      top: calc(100% - 3px);
    }
  }

  &:hover {
    & > span {
      &:nth-child(1) {
        transform: translateY(calc(50% - 2px));
      }

      &:nth-child(3) {
        transform: translateY(1px);
      }
    }
  }

  &:active,
  &.pressed {
    & > span {
      &:nth-child(1) {
        transform: translateY(calc(-50% - 0px)) rotate(45deg);
        top: 50%;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: translateY(-50%) rotate(-45deg);
        top: 50%;
      }
    }
  }
}
