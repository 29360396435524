.wrapper {
  display: flex;
  justify-content: flex-end;

  &.black {
    svg {
      path {
        fill: black;
      }
    }
  }

  &.inBurgerMenu {
    justify-content: center;
    align-self: center;
    margin-bottom: 20px;

    & > a {
      margin-right: 30px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &.authorise {
    justify-content: space-around;

    & > a {
      margin-right: 0px;
    }

    .profile {
      margin-right: 0px;
    }
  }

  & > a {
    margin-right: 30px;
  }

  .profile {
    margin-right: 30px;
  }


  svg {
    max-width: 20px;
    max-height: 20px;

    path {
      fill: white;
      transition: fill 0.3s ease;
    }
  }
}

.icon {
  width: min-content;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media(max-width: 1365px) {
    margin-right: 25px;
  }

  &.inBurgerMenu {
    display: block;
  }
}

.like {
  position: relative;
  width: 23px;
  height: 19px;

  @media(max-width: 767px) {
    display: none;
  }

  &.filled {
    &::before {
      content: attr(data-length);
      position: absolute;
      z-index: -1;
      top: -13px;
      left: -1px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #2B3446;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      line-height: 0;
      color: white;
    }
  }
}

.statistic {
  position: relative;
  width: 18px;
  height: 18px;

  &.filled {
    &::before {
      content: attr(data-length);
      position: absolute;
      z-index: -1;
      top: -13px;
      left: -2px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #2B3446;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      line-height: 0;
      color: white;
    }
  }

  @media(max-width: 767px) {
    display: none;
  }
}

.card {
  width: 26px;
  height: 22px;
  position: relative;
  z-index: 1;

  @media(max-width: 767px) {
    display: none;
  }
}

.digit {
  position: absolute;
  top: -13px;
  left: 2px;
  z-index: -1;
  background-color: #2B3446;
  width: 20px;
  height: 20px;
  display: block;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 11px;
  line-height: 0;
  color: white;
}

.profile {
  width: 22px;
  height: 22px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  &.autorise {
    border-radius: 50%;
    outline: 2px solid white;
    background-color: #00B1FF;

    & > svg {
      max-width: 30px;
      max-height: 30px;
      width: 30px;
      height: 30px;
      margin-top: 5px;
    }

    &.black {
      outline: 2px solid #000;
      background-color: #fff;

      & > svg {
        & > path {
          fill: #000;
        }
      }
    }
  }

  &.inBurgerMenu {
    display: none;
  }
}

button {
  background-color: transparent;
}
