.card {
  width: 100%;
  box-shadow: 5px 5px 8px 5px rgba(#000, 0.3);
  transition: box-shadow 0.3s ease;
  background-color: #e0e1e4;
  border-radius: 10px;
  overflow: hidden;

  height: 100%;

  display: flex;
  flex-flow: column nowrap;

  &:hover {
    box-shadow: 5px 5px 8px 5px #45c6c2;
  }
  &.active {
    box-shadow: 5px 5px 8px 5px #45c6c2;
  }

  &.category {
    overflow: visible;
    box-shadow: none;
    background-color: transparent;
    border-radius: none;

    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-end;

    img {
      box-shadow: 5px 5px 8px 5px rgba(#000, 0.3);
      transition: box-shadow 0.8s ease;
      border-radius: 5px;
      width: 100%;
      aspect-ratio: 1 / 1;
      object-fit: contain;
      display: block;

      @media(max-width: 767px) {
        box-shadow: 3px 3px 6px 3px rgba(#000, 0.3);
      }
    }
    // }

    &:hover {
      img {
        box-shadow: 5px 5px 8px 5px #45c6c2;
      }
    }
    &.active {
      img {
        box-shadow: 5px 5px 8px 5px #45c6c2;
      }
    }

    // a {
    // }

    @media(max-width: 767px) {
      gap: 10px;
    }
  }

  @media(max-width: 767px) {
    box-shadow: 3px 3px 6px 1px rgba(#000, 0.3);
  }
}
