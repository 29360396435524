.image {
  width: 100%;
  height: 100%;
  max-height: 700px;

  img {
    width: 100%;
    height: 100%;
    max-height: 700px;
    object-fit: contain;
    object-position: center;
  }
}
