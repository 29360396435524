.image {
  width: 100%;
  margin-bottom: 40px;

  & > img {
    width: 100%;
    height: 100%;
    max-height: 600px;
    object-fit: cover;
    display: block;
  }

  @media (max-width: 1365px) {
    margin-bottom: 45px;
  }

  @media (max-width: 767px) {
    margin-bottom: 22px;
  }
}

.article {
  color: white;

  & > p {
    font-weight: 300;
    font-size: 18px;
    line-height: 1.3;

    &:not(:last-child) {
      padding-bottom: 8px;
    }

    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  & > ul {
    padding: 7px 0;

    & > li {
      font-weight: 300;
      line-height: 1.3;
      padding-left: 20px;
      position: relative;

      &::after {
        content: '-';
        position: absolute;
        padding-left: 15px;
        top: 0;
        left: -15px;
      }
    }
  }
}
