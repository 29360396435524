.title {
  color: #000;
  // line-height: .5;
  margin-bottom: 10px;

  @media(max-width: 1365px) {

  }

  @media(max-width: 767px) {

  }
}

.categories {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(min-content, 1fr);
  column-gap: 35px;
  row-gap: 40px;
  margin-bottom: 80px;

  @media (max-width: 1365px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 50px;
    grid-auto-rows: unset;
    column-gap: 20px;
    row-gap: 20px;
  }

  @media (max-width: 767px) {
    gap: 15px;
    margin-bottom: 30px;
  }
}

.category {
  width: 100%;
}

.filters {
  position: relative;
  padding-top: 25px;
  padding-bottom: 23px;
  margin-bottom: 30px;

  &::before,
  &::after {
    content: '';
    height: 1px;
    width: 100%;
    background-color: #000;
    display: block;
    position: absolute;

    @media (max-width: 1365px) {
      display: none;
    }
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }

  @media(max-width: 767px) {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.products {
  .products__title {
    margin-bottom: 27px;
    color: #1d1d1b;
    font-size: 21px;
    font-weight: 500;

    @media(max-width: 767px) {
      font-size: 14px;
    }
  }
}
