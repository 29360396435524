.wrapper {
  position: relative;
  width: auto;

  & > input {
    background-color: transparent;
    border: 1px solid white;
    border-radius: 25px;
    padding: 17px 20px 10px 25px;
    color: white;
    font-size: 18px;
    line-height: 1;
    width: 100%;

    transition: border-color 0.3s ease;

    &[type='checkbox'] {
      opacity: 0;
      width: 100%;
      padding: 5px;
      margin: 20px 0;
      cursor: pointer;

      & + .placeholder {
        width: 16px;
        height: 16px;
        background-color: transparent;
        position: absolute;
        left: 0;
        top: 50%;
        border: 1px solid white;
        transform: translateY(-50%);
        pointer-events: none;
        transition: background-color 0.1s ease;
      }

      &:checked {
        & + .placeholder {
          background-color: #fff;
        }
      }
    }

    &.black {
      border: 1px solid #000;
      color: #000;
    }

    &.count {
      padding: 4px 10px;
      border-radius: 5px;
      text-align: center;
    }
  }

  & > textarea {
    background-color: transparent;
    border: 1px solid white;
    border-radius: 25px;
    padding: 20px 20px 10px 25px;
    color: white;
    font-size: 18px;
    line-height: 1;

    transition: border-color 0.3s ease;

    & + .placeholder {
      top: 25px;

      &.active,
      &.filled {
        transform: translate(20px, -140%) scale(0.8);
        transform-origin: left;
      }
    }
  }

  .input_error {
    border-color: red;
  }
}

.placeholder {
  font-weight: 300;
  font-size: 15px;
  position: absolute;
  top: 50%;
  left: 10px;
  color: white;
  transform: translate(0px, -50%) scale(1);
  pointer-events: none;
  width: auto;

  transition: transform 0.3s ease;

  &.active,
  &.filled {
    transform: translate(20px, -140%) scale(0.8);
    transform-origin: left;
  }

  &.black {
    color: #000;
  }
}

.error {
  position: absolute;
  top: -20px;
  right: 0;
  text-align: right;
  color: red;
  font-size: 16px;

  @media(max-width: 767px) {
    font-size: 14px;
  }
}
