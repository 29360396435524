.title {
  // display: flex;
  // align-items: flex-end;

  margin-bottom: 11px;

  color: #fff;
  font-weight: 500;
  line-height: normal;

  .year__number {
    margin-right: 10px;

    font-size: 65px;

    @media (max-width: 1365px) {
      // font-size: 74.4px;
    }

    @media (max-width: 767px) {
      font-size: 34px;
      width: min-content;
    }

  }

  .year__text {
    font-size: 47px;
    padding-left: 10px;

    @media (max-width: 1365px) {
      // font-size: 55px;
    }
    @media (max-width: 767px) {
      font-size: 24px;
    }
  }
}

.text {
  color: #fff;
  font-size: 20px;
  font-weight: 300;
  line-height: normal;

  @media (max-width: 1365px) {
    font-size: 18px;
  }
  @media (max-width: 767px) {
    font-size: 14px;
  }
}
