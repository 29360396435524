.buttons {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;

  @media (max-width: 767px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
  }
}

.filter {
  margin-right: auto;
  width: min-content;
  min-width: 250px;

  @media (max-width: 767px) {
    grid-row: 2/3;
    grid-column: 1/3;
    min-width: 100%;
  }
}

.clean {
  width: min-content;
  margin-right: 30px;

  @media (max-width: 767px) {
    grid-row: 1/2;
    grid-column: 1/2;
  }
}

.inCart {
  text-align: right;
  width: auto;
  color: white;

}

.total {
  display: flex;

  @media (max-width: 767px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 10px;
  }

  @media (max-width: 450px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.promo {
  margin-right: 20px;
  display: flex;
  white-space: nowrap;
  position: relative;

  input {
    padding: 10px;
  }

  .submit {
    position: absolute;
    right: 25px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: auto;

    display: flex;
    align-items: center;
  }
}

.continue {
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    grid-row: 2/3;
    width: 100%;
  }

  @media (max-width: 450px) {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
  }
}

.result {
  color: white;
  width: auto;
  white-space: nowrap;
  margin-right: 50px;

  .result_nds {
    font-weight: 300;
    margin-bottom: 5px;
  }

  @media (max-width: 767px) {
    grid-column: 2/3;
    grid-row: 1/2;
    text-align: end;
    width: 100%;
    margin-right: 0;
  }

  @media (max-width: 450px) {
    grid-column: 1/2;
    grid-row: 1/2;
    text-align: center;
    margin-bottom: 15px;
  }
}

.buy {
  display: flex;justify-content: flex-end;
  align-items: center;
  width: auto;

  @media (max-width: 767px) {
    grid-column: 2/3;
    grid-row: 2/3;
  }

  @media (max-width: 450px) {
    grid-column: 1/2;
    grid-row: 3/4;
    margin-left: auto;
    margin-right: auto;
  }
}

.isEmpty {
  color: white;
  text-align: center;
}
