.title {
  margin-bottom: 56px;

  color: #fff;
  font-family: Stem;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
