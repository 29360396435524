.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contact {
  margin-bottom: 19px;
  width: 100%;

  & > a,
  & > span {
    color: #fff;
    font-family: Stem;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    width: 100%;

    @media (max-width: 1365px) {
      font-size: 14px;
      white-space: normal;
    }
  }
}
