.wrapper {
  background-color: #2B3446;
  min-width: 500px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;

  @media(max-width: 1365px) {
    padding: 50px 30px;
  }

  @media (max-width: 767px) {
    min-width: 350px;
  }

  & > button {
    align-self: flex-end;
  }
}

.fields {
  margin-bottom: 20px;
}

.password_second {
  margin-bottom: 20px;
}