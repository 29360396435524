.wrapper {
}

.categories {
  .category {
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      margin-top: 102px;
      margin-bottom: 98px;
      background-color: #fff;

      @media (max-width: 1365px) {
        margin-top: 40px;
        margin-bottom: 30px;
      }

      @media(max-width: 767px) {
        height: 0;
      }
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }
}
