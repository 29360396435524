.wrapper {
}

.inner {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  position: relative;

  &::before {
    content: "›";
    position: absolute;
    font-size: 24px;
    line-height: .5;
    color: white;
    left: 0px;
    top: 12px;
    transition: transform 0.3s ease;
    transform-origin: 50% 70%;
  }

  &.open {
    &::before {
      transform: rotate(90deg);
    }
  }

  .innerTitle {
    font-size: 24px;
    color: white;
    font-weight: 500;
    text-align: left;
    margin-bottom: 10px;
    padding-left: 30px;
    position: relative;
    left: -30px;
  }
}

.categoriesContainer {
  overflow: hidden;
  transition: height .3s ease;
}

.categoriesWrapper {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
}

.categoriesTitle {
  color: white;
  font-weight: 500;
  margin-bottom: 10px;
}

.certificateWrapper {
  margin-bottom: 20px;
}

.certItem {
  display: flex;
  margin-bottom: 7px;
  padding-left: 10px;

  .certTitle {
    width: auto;
    line-height: 1.3;
  }

  .certFile {
    width: auto;
    display: flex;
    color: white;
    font-weight: 300;

    &:visited {
      color: hsl(199, 94%, 80%);
    }

    .title {
    }
  }
}

.icon {
  color: inherit;
  width: 16px;
  height: 16px;
  margin-right: 7px;
  margin-bottom: 2px;
}
