.nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.link {
  display: inline;
  width: min-content;

  color: #fff;
  font-family: Stem;
  font-size: 23.334px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (max-width: 1365px) {
    font-size: 16.13px;
  }
}
