.items {
  display: flex;
  flex-direction: column;

  @media (max-width: 1365px) {
  }

  @media (max-width: 767px) {
    gap: 0;
  }
}

.item {
  position: relative;
  padding-bottom: 30px;
  padding-top: 30px;

  @media(max-width: 767px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &:first-child {
    padding-top: 0;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(white, .5);
    height: 1px;
  }
}
