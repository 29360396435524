.button {
  width: min-content;
  height: auto;

  background: transparent;
  border: 1px solid #fff;
  border-radius: 20px;

  color: #fff;
  font-family: Stem;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  padding: 3px 15px;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.8);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0px 0px 1px 1px rgba(255, 255, 255, 0.8);
  }

  &.black {
    border: 1px solid rgba(black, 0.5);
    color: #000;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.8);
    font-weight: 500;

    &:hover {
      box-shadow: 0px 0px 10px 0px rgba(black, 0.2);
    }
  }

  &.bigPadding {
    font-weight: 400;
    padding: 11px 30px 10px;
    box-shadow: 0px 0px 3px 1px #fff;
    display: inline-block;
    white-space: nowrap;

    &:hover {
      box-shadow: 0px 0px 5px 3px #fff;
    }
  }

  &.coparison {
    font-weight: 400;
    padding: 11px 30px 10px;
    box-shadow: 0px 0px 3px 1px #fff;
    display: inline-block;
    white-space: nowrap;

    &:hover {
      box-shadow: 0px 0px 5px 3px #fff;
    }

    @media (max-width: 767px) {
      padding: 11px 10px 10px;
      // width: 160px;
      white-space: nowrap;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }
}
