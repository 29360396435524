.activeItem {
  display: flex;
  justify-content: space-between;

  .description {
    width: 453px;
    color: #fff;
    font-size: 18px;
    font-weight: 300;

    @media (max-width: 1365px) {
      font-size: 15px;
    }

    @media (max-width: 767px) {
      font-size: 14px;
      width: 100%;
    }
  }

  .activeItemImage {
    position: relative;
    top: -90px;

    width: 430px;
    height: 603px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @media (max-width: 767px) {
      margin-top: 20px;
      top: 0;

      width: 100%;
      height: auto;
      max-width: 530px;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}

.bigScreen {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;

  img {
    width: 708px;
    height: 100%;
    object-fit: contain;
    display: block;

    @media(max-width: 767px) {
      width: 90vw;
      height: auto;
    }
  }
}

.nothing {
  color: white;
  text-align: center;
}

.next,
.prev {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  width: min-content;
}

.next {
  right: 40px;
}

.prev {
  left: 30px;
}
