.wrapper {
  padding: 50px 7px 7px;
  border-radius: 15px;

  display: flex;
  flex-direction: column;

  & > button {
    margin-top: 30px;
  }

  @media(max-width: 767px) {
    padding-top: 30px;
  }
}

.field {
  min-width: 450px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  @media (max-width: 767px) {
    // width: 80vw;
    min-width: 260px;
    max-width: 280px;
  }
}

.title {
  color: white;
  margin-bottom: 20px;

  @media(max-width: 767px) {
    font-weight: 500;
  }
}

.agree {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.required {
  color: white;
  font-weight: 300;
  margin-top: 15px;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    margin-bottom: 0;
  }
}

