.filters {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 30px;

  @media (max-width: 1365px) {
    display: none;

    background-color: #e0e1e4;
    border-radius: 20px;
    box-shadow: 5px 5px 8px 5px rgba(0, 0, 0, 0.3);
    &.active {
      display: flex;
      flex-direction: column;
      grid-gap: 10px;
      margin-bottom: 10px;
    }
  }
}
.filterWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  & > button {
    flex: 1 0 100%;
    width: 100%;
    height: 100%;

    @media (max-width: 1365px) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  @media (max-width: 1365px) {
    width: 100%;
  }

  &.filled {
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translateY(-50%);
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #2B3446;
    }
  }
}
.reset {
  display: flex;
  @media (max-width: 1365px) {
    width: 100%;
    button {
      padding-top: 10px;
      padding-bottom: 10px;
      width: 100%;
    }
  }
}

.modal {
  display: none;
  @media (max-width: 1365px) {
    display: block;
    margin-bottom: 20px;
  }
}
.show {
  &.filled {
    button {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #2B3446;
      }
    } 
  }
}