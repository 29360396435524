.name {
  width: min-content;
  white-space: nowrap;
  color: #fff;
  font-size: 21px;
  font-weight: 300;
  line-height: 1.3;
  margin-bottom: 20px;

  @media (max-width: 1365px) {
    font-size: 16px;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    white-space: inherit;
    width: 100%;
  }
}

.contacts {
  margin-bottom: 30px;
}

.socials {
  margin-bottom: 80px;
  width: min-content;

  @media (max-width: 767px) {
    margin-bottom: 60px;
  }
}

.map {
  width: 100%;
  height: 589px;
  margin-bottom: 139px;

  @media (max-width: 1365px) {
    height: 333px;
    margin-bottom: 54px;
  }

  @media (max-width: 767px) {
    height: 280px;
    margin-bottom: 21px;
  }
}

.separator {
  width: 100%;
  height: 1px;
  margin-bottom: 49px;
  background-color: #fff;

  @media (max-width: 1365px) {
    margin-bottom: 28px;
  }

  @media (max-width: 767px) {
    margin-bottom: 18px;
  }
}

.filials__title {
  margin-bottom: 48px;
  color: #fff;
  font-size: 42px;
  font-weight: 500;
  line-height: normal;

  @media(max-width: 1365px) {
    font-size: 36px;
  }

  @media(max-width: 767px) {
    font-size: 24px;
  }
}
