.title {
  color: black;
}

.desc {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: min-content 1fr;
  column-gap: 50px;
  margin-bottom: 140px;

  @media (max-width: 1365px) {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 30px;
    margin-bottom: 30px;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #000;
    }
  }
}

.image {
  grid-area: 1/1/3/2;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top;
  }
}

.desc_title {
  grid-area: 1/2/2/3;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.1;
  padding-bottom: 15px;
}

.desc_text {
  grid-area: 2/2/3/3;
  white-space: pre-wrap;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.3;
}

.products {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 35px;
  row-gap: 35px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: -70px;
    width: 100%;
    height: 1px;
    background-color: rgba(black, 0.3);
  }
}
