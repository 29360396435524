.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.input {
  margin-bottom: 36px;

  @media(max-width: 1365px) {
    margin-bottom: 20px;
  }
}

.send {
  width: 100%;

  & > button {
    width: 100%;
  }
}

.success_sending {
  width: 100%;
  max-width: 600px;
  background-color: #2b3446f2;
  padding: 50px 120px;
  border-radius: 15px;

  @media(max-width: 1365px) {
    padding: 30px;
  }

  & > p {
    color: white;
    text-align: center;

    font-size: 42px;
    font-weight: 500;
    letter-spacing: 2.1px;

    @media(max-width: 1365px) {
      font-size: 36px;
    }

    @media(max-width: 767px) {
      font-size: 24px;
      letter-spacing: normal;
    }

    &:last-child {
      background-color: #fff;
      color: #000000;
      font-size: 25px;
      font-weight: 300;
      white-space: normal;

      border-radius: 10px;
      padding: 15px 10px;
      margin-top: 30px;

      @media(max-width: 1365px) {
        margin-top: 25px;
        font-size: 18px;
      }

      @media(max-width: 767px) {
        font-size: 16px;
        margin-top: 20px;
      }
    }
  }
}
