.title {
  margin-bottom: 35px;
}

.subInfo {
  display: flex;
  margin-bottom: 40px;

  & > p {
    width: auto;
  }
}

.date {
  padding-right: 20px;
  color: #fff;
  font-size: 21px;
  font-weight: 300;
  position: relative;

  &::after {
    content: '/';
    padding-left: 20px;
    color: white;

    @media (max-width: 767px) {
      display: none;
    }
  }

  &:last-child {
    &::after {
      content: none;
    }
  }

  @media (max-width: 1365px) {
    font-size: 12px;
  }

  @media (max-width: 767px) {
    font-size: 14px;
  }
}

.time_to_read {
  color: #fff;
  font-size: 21px;
  font-weight: 300;
  @media (max-width: 1365px) {
    font-size: 12px;
  }

  @media (max-width: 767px) {
    font-size: 14px;
  }
}

.slider {
  margin-bottom: 40px;
}

.description {
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.4;

  @media (max-width: 1365px) {
    font-size: 14px;
  }

  & ul, ol {
    list-style-type: decimal;
    padding-left: 17px;
    margin-top: 10px;
    margin-bottom: 10px;

    & li {
      &:not(:last-child) {
        margin-bottom: 7px;
      }

      line-height: 1.3;
    }
  }

  & ul {
    list-style-type: circle;
  }

  & h2 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  & h3 {
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: 500;
    font-size: 1.3rem;
  }

  & table {
    display: block;
    overflow-x: auto;

    td {
      border: 1px solid #fff;
      padding: 5px;
    }
  }

  & p {
    padding: 5px 0;
  }

  & a {
    color: #fff;
    text-decoration: underline;
  }
}

.article {
  padding: 0 0 50px;
  margin-bottom: 50px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #fff;
    opacity: 0.5;
  }

  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
}

.video {
  margin-bottom: 92px;
}
