.title {
  margin-bottom: 64px;

  color: #fff;
  font-size: 25px;
  font-weight: 500;
  line-height: normal;

  @media(max-width: 1365px) {
    margin-bottom: 10px;
  }
}
