.login {
  background-color: #2b3446;
  padding: 50px;
  width: min-content;

  display: flex;
  flex-flow: row wrap;
  align-content: center;
  border-radius: 15px;

  @media (max-width: 767px) {
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
  }
}

.button {
  flex: 0 1 100px;
  font-size: 16px;
  color: #a4a4a4;

  border-top: 1px solid #494949;
  border-left: 1px solid #494949;
  border-right: 1px solid #494949;
  padding: 10px 30px;

  transition: border-color 0.3s ease;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;


  &.active {
    color: white;
    border-color: white;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      height: 1px;
      width: 100%;
      background-color: #2b3446;
    }

    @media(max-width: 767px) {
      &::after {
        display: none;
      }
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    flex: 0 1 60px;

    margin-bottom: 15px;
    border: 1px solid #8f8f8f;
    border-radius: 10px;
    padding: 5px 20px;
    flex-basis: 35px;
  }
}

.content {
  flex: 0 1 100%;
  height: auto;
  transition: height 0.5s ease, width 0.5s ease;
  overflow: hidden;
  border-top: 1px solid white;
  width: fit-content;
}

.successRegister {
  width: 100%;
  max-width: 600px;
  background-color: #2b3446f2;
  padding: 50px 120px;
  border-radius: 15px;

  & > p {
    color: white;
    text-align: center;

    font-size: 40px;
    font-weight: 500;
    letter-spacing: 2.1px;

    &:last-child {
      background-color: #fff;
      color: #000000;
      font-size: 20px;
      font-weight: 300;
      white-space: normal;

      border-radius: 10px;
      padding: 15px 10px;
      margin-top: 30px;

      @media(max-width: 767px) {
        font-size: 18px;
      }
    }

    @media(max-width: 767px) {
      font-size: 30px;
    }
  }
}