.tabs {
  display: flex;
  width: auto;
  position: relative;
  padding-left: 10px;
  margin-top: 20px;

  // @media (max-width: 1365px) {
  //   margin-bottom: 32px;
  // }
  // @media (max-width: 767px) {
  //   margin-bottom: 25px;
  // }
}

.button {
  width: auto;
  border-top: 1px solid rgba(white, .3);
  border-left: 1px solid rgba(white, .3);
  border-right: 1px solid rgba(white, .3);
  padding: 10px 30px;
  transition: border-color 0.3s ease;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
  // background-color: red;

  @media(max-width: 1365px) {
    padding: 7px 20px;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background-color: #35b4d8;
    z-index: 0;
    opacity: 0;

    transition: opacity .3s ease;
  }

  & > span {
    color: rgba(white, .3);
    font-size: 24px;
    font-weight: 500;
    transform: scale(0.8);
    display: block;

    transition: transform 0.3s ease, color 0.3s ease;

    @media (max-width: 1365px) {
      font-size: 30px;
    }

    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  // &:not(:last-child) {
  //   margin-right: 30px;
  // }

  &.active {
    border-color: white;
    z-index: 5;

    &::after {
      opacity: 1;
    }

    & > span {
      color: #fff;
      transform: scale(1);
    }
  }
}
