.inner {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 35px;
    row-gap: 35px;

    &.cards {}

    &.list {
        grid-template-columns: repeat(1, 1fr);
    }

    &.more {
        grid-template-columns: repeat(1, 1fr);
    }

    @media (max-width: 1365px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
}

.itemStyleControl {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: flex-end;

    button {
        width: min-content;

        &.active {
            svg {
                path {
                    fill: #00b1ff;
                    transition: 0.3s ease fill;
                }
            }
        }
    }

    &.white {
        .active {
            path {
                fill: #fff;
            }
        }
    }
}

.icon {
    padding: 10px;
    width: 40px;
    height: 40px;

    svg {
        width: 100%;
        height: 100%;
    }
}
