.wrapper {
  display: flex;

  &:hover {
    .image {
      img {
        filter: grayscale(0%) brightness(1);
        transform: scale(1.03);

        @media (max-width: 1365px) {
          transform: scale(1);
        }
      }
    }
  }
}

.image {
  position: relative;
  flex: 0 0 auto;
  width: 266px;
  aspect-ratio: 1 / 1;

  margin-right: 34px;

  border-radius: 15px;
  overflow: hidden;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;

    filter: grayscale(80%) brightness(0.8);
    transform: scale(1);
    transition: 0.2s ease filter, transform 0.7s ease-in-out;

    @media (max-width: 1365px) {
      filter: grayscale(0) brightness(1);
    }
  }

  @media (max-width: 767px) {
    width: 40%;
  }
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .date {
    margin-bottom: 23px;

    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;

    &::after {
      content: '';
      display: block;
      margin-top: 6px;

      width: 100%;
      height: 1px;
      background-color: #fff;
    }
  }

  .title {
    margin-bottom: 3px;

    color: #fff;
    font-size: 16px;
    font-weight: 700;

    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
  }

  .description {
    color: #fff;
    font-size: 14px;
    font-weight: 300;
  }

  .read_more {
    margin-bottom: 5px;
    text-align: right;
  }
}
