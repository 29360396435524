.inner {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 35px;
  row-gap: 35px;

  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 6px;
    row-gap: 6px;
  }
}

.sectionSearch {
  width: min-content;
  min-width: 400px;
  margin: 0 auto;

  @media (max-width: 767px) {
    min-width: 300px;
  }
}

.section {
  margin-top: 40px;
  margin-bottom: 40px;
}

.title {
  margin-bottom: 20px;
  font-size: 30px;
}
.search {
  min-height: 70vh;
}

.notFound {
  color: white;
  text-align: center;
  margin-top: 100px;
}
