.projects {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 95px;
  grid-column-gap: 34px;

  @media (max-width: 1365px) {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 60px;
    grid-column-gap: 20px;
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 40px;
  }
}

.project {
  position: relative;

  @media(max-width: 767px) {
    &::after {
      content: '';
      position: absolute;
      bottom: -20px;
      left: 0;
      right: 0;
      height: 1px;
      background-color: rgba(white, 0.5);
    }
  }
}
