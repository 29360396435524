.pagination {
  display: flex;
  justify-content: flex-end;
  width: min-content;
  margin: 40px auto;


  &.black {
    .page_link {
      color: #000;
    }
  }
}

.page_item {
  white-space: nowrap;
  border: 1px solid white;
  border-radius: 5px;
  width: min-content;
  min-width: 35px;
  text-align: center;
  transition: background-color .3s ease, opacity .3s ease;
  opacity: 1;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &.active {
    background-color: #00B1FF;
    outline: 1px solid white;
    outline-offset: -2px;
    box-shadow: 0 0 5px 1px rgba(white, .5);

    .page_link {
      color: white;
    }
  }

  &:hover {
    background-color: #00B1FF;

    .page_link {
      color: white;

      &.next, &.prev {
        color: #00B1FF;
      }
    }
  }

  &.next, &.prev {
    border: none;

    &:hover {
      background-color: unset;
    }
  }

  &.disable {
    opacity: 0;
    pointer-events: none;
  }
}



.page_link {
  color: white;
  display: block;
  padding: 7px;
  transition: color .3s ease;

  &.prev, &.next {
    &:hover {
    }
  }

  &.active {
  }

  &.disable {
  }
}