.subInfo {
  display: flex;
  margin-bottom: 40px;

  & > p {
    width: auto;
  }

  @media (max-width: 1365px) {
    margin-bottom: 28px;
  }
  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
}

.date {
  padding-right: 20px;

  color: #fff;
  font-size: 21px;
  font-weight: 300;
  position: relative;

  white-space: nowrap;

  &::after {
    content: '/';
    padding-left: 20px;
    color: white;
  }

  @media (max-width: 1365px) {
    font-size: 18px;
  }

  @media (max-width: 767px) {
    font-size: 14px;
  }
}

.time_to_read {
  color: #fff;
  font-size: 21px;
  font-weight: 300;

  @media (max-width: 1365px) {
    font-size: 18px;
  }

  @media (max-width: 767px) {
    font-size: 14px;
  }
}

.slider {
  margin-bottom: 40px;

  @media(max-width: 767px) {
    margin-bottom: 20px;
  }
}

.description {
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.4;
  white-space: pre-wrap;

  @media (max-width: 1365px) {
    font-size: 16px;
  }

  @media (max-width: 767px) {
    font-size: 14px;
  }
}

.article {
  padding: 0 0 50px;
  margin-bottom: 50px;
  position: relative;

  @media (max-width: 1365px) {
    margin-bottom: 42px;
    margin-top: 42px;
  }

  @media (max-width: 767px) {
    margin-bottom: 15px;
    margin-top: 15px;
    padding-bottom: 20px;
  }
}

.lastNews {
  position: relative;
  padding-bottom: 75px;
  margin-bottom: 20px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #fff;
    opacity: 0.5;
  }

  @media (max-width: 1365px) {
    padding-bottom: 22px;
  }

  @media (max-width: 767px) {
    padding-bottom: 30px;
  }
}

