.wrapper {
  display: flex;
  align-items: center;

  position: relative;

  height: 134px;

  padding-left: 65px;
  padding-right: 30px;

  background-color: #2d3241;

  border-radius: 28px;

  z-index: 2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media (max-width: 1365px) {
    height: 75px;

    padding-left: 37px;
    padding-right: 17px;
  }
  @media (max-width: 767px) {
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    
    padding-left: 12px;
    padding-right: 18px;

    border-radius: 14px;
  }

  .title {
    width: min-content;
    white-space: nowrap;
    color: #fff;
    font-family: Stem;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: auto;

    @media (max-width: 1365px) {
      font-size: 18px;
    }
    @media (max-width: 767px) {
      margin-right: auto;

      font-size: 16px;
      white-space: inherit;

      text-align: left;
    }

  }

  .payday {
    width: min-content;
    white-space: nowrap;

    margin-right: 52px;

    color: #fff;
    font-family: Stem;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    @media (max-width: 1365px) {
      font-size: 23px;
    }
    @media (max-width: 767px) {
      font-size: 13px;
      margin-right: 10px;
      margin-left: 10px;
      white-space: inherit;
      width: 100%;
      text-align: right;
    }

  }
  .arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 10px;

    transition: transform 0.3s ease;

    &.active {
      transform: rotate(180deg);
      transform-origin: 50% 50%;
    }

    @media (max-width: 767px) {
      justify-content: flex-start;
    }
  }
}
.description {
  position: relative;
  top: -28px;

  background-color: #fff;

  height: 0;

  overflow: hidden;

  transition: height 0.3s ease;

  z-index: 1;

  border-end-end-radius: 28px;
  border-bottom-left-radius: 28px;

  white-space: pre-wrap;

  p {
    padding-top: 58px;
    padding-bottom: 61px;
    padding-left: 66px;
    padding-right: 76px;

    @media (max-width: 1365px) {
      padding-top: 43px;
      padding-left: 37px;
      padding-right: 57px;
      padding-bottom: 49px;
    }
    @media (max-width: 767px) {
      padding-left: 21px;
      padding-right: 23px;
      padding-bottom: 31px;
    }  
  }

  @media (max-width: 1365px) {}
  @media (max-width: 767px) {
    border-end-end-radius: 14px;
    border-bottom-left-radius: 14px;
  }
}
