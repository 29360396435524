.items {
  display: flex;
  gap: 34px;
}

.item {
  display: grid;
  grid-template-rows: minmax(min-content, 110px) 1fr auto;
  height: min-content;
  padding-left: 17px;
  padding-right: 17px;

  &:not(:first-child) {
    padding-left: 10px;
  }

  &:not(:last-child) {
    padding-right: 10px;
  }

  .itemTitle {
    padding-bottom: 15px;
    min-height: 125px;
    color: #fff;
    font-size: 21px;
    font-weight: 500;
    line-height: 1.3;
    width: 267px;

    display: flex;
    justify-content: center;
    align-items: flex-end;

    @media (max-width: 1365px) {
      font-size: 20px;
      height: 100%;
    }

    @media (max-width: 767px) {
      font-size: 14px;
      font-weight: 300;
      min-height: 70px;
      width: 100%;
      padding-bottom: 10px;
    }

    & > span {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .itemImage {
    width: 100%;
    aspect-ratio: 1 / 1;
    overflow: hidden;
    border-radius: 15px;
    margin-bottom: 18px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media(max-width: 767px) {
      margin-bottom: 10px;
    }
  }

  .itemText {
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    line-height: 140%;
  }

  @media (max-width: 1365px) {
    grid-template-rows: minmax(min-content, 110px) 1fr auto;
  }

  @media (max-width: 767px) {
    grid-template-rows: minmax(min-content, 50px) 1fr auto;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }
}
