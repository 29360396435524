.wrapper {
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 64px;
  color: #fff;
  font-size: 25px;
  font-weight: 500;

  @media(max-width: 767px) {
    font-size: 22px;
    margin-bottom: 30px;
  }
}

.agree {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media(max-width: 767px) {
    margin-top: 0;
    margin-bottom: 15px;

  }
}

.fields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 30px;
  column-gap: 35px;

  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 22px;
  }
}

.success_sending {
  width: 100%;
  max-width: 600px;
  background-color: #2b3446f2;
  padding: 50px 120px;
  border-radius: 15px;

  & > p {
    color: white;
    text-align: center;

    font-size: 42px;
    font-weight: 500;
    letter-spacing: 2.1px;

    &:last-child {
      background-color: #fff;
      color: #000000;
      font-size: 25px;
      font-weight: 300;
      white-space: normal;

      border-radius: 10px;
      padding: 15px 10px;
      margin-top: 30px;
    }
  }
}

.dealers {
  padding-top: 23px;
  padding-bottom: 50px;

  @media(max-width: 767px) {
    padding-bottom: 30px;
  }
}
