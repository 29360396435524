.arrow {
  width: min-content;

  svg {
    transform: rotate(180deg);
    overflow: visible;
  }

  path {
    &:nth-child(2), &:nth-child(4) {
      filter: blur(0px);
      transition: filter .3s ease, stroke-width .3s ease;
    }
  }

  &:hover {
    path {
      &:nth-child(2), &:nth-child(4) {
        filter: blur(3px);
        stroke-width: 1.2;
      }
    }
  }

  @media (max-width: 767px) {
    width: 25px;
    height: 25px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
