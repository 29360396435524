.wrapper {
  // display: flex;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto 1fr;

  @media (max-width: 1365px) {
  }

  @media(max-width: 767px) {
    grid-template-columns: 1fr;
    grid-template-rows: unset;
  }
}

.title {
  grid-area: 1/1/2/2;
}

.description {
  grid-area: 1/2/3/3;

  color: #fff;
  font-family: Stem;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.3;

  white-space: pre-wrap;

  @media (max-width: 1365px) {
    font-size: 14px;
  }

  @media(max-width: 767px) {
    grid-area: 2/1/3/2;
  }
}

.navbar {
  grid-area: 2/1/3/2;

  display: flex;
  flex-direction: column;

  @media(max-width: 767px) {
    display: none;
  }

  & > a {
    color: white;
    font-size: 24px;
    padding: 7px 0;
    position: relative;
    width: min-content;

    &:after {
      content: "";
      position: absolute;
      left: 0; bottom: 5px;
      height: 1px;
      width: 0;
      background-color: #fff;
      transition: width .3s ease
    }

    &:hover {
      &:after {
        width: 100%;
      }
    }
  }
}
