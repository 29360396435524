.wrapper {
  padding-top: 12px;
  width: min-content;
  display: flex;
  flex-direction: column;
  height: 300px;

  @media (max-width: 767px) {
    height: 200px;
  }

  &.active {
    .image {
      img {
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
        filter: drop-shadow(0px 0px 6px #fff);
      }
    }
  }
  &.removeActive {
    .image {
      img {
      }
    }
  }
}

.image {
  width: 146px;
  height: 217px;
  margin-bottom: 12px;

  @media (max-width: 767px) {
    width: 100px;
    height: 150px;
  }

  img {
    padding-top: 36px;
    padding-left: 7px;
    padding-right: 7px;
    display: block;

    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s ease padding-top, 0.3s ease padding-right,
      0.3s ease padding-left, 0.3s ease filter;
    filter: drop-shadow(0px 0px 8px 2px transparent);
  }
}

.title {
  text-align: center;
  color: #fff;
  font-family: Stem;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media (max-width: 767px) {
    font-size: 10px;
  }
}

@keyframes activeAnimation {
  0% {
    padding-top: 36px;
    padding-left: 7px;
    padding-right: 7px;
    box-shadow: 0px 0px 8px 2px transparent;
  }
  50% {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    box-shadow: 0px 0px 8px 2px transparent;
  }
  100% {
    box-shadow: 0px 0px 8px 2px #fff;
  }
}

@keyframes notActiveAnimation {
  100% {
    padding-top: 36px;
    padding-left: 7px;
    padding-right: 7px;
    box-shadow: 0px 0px 8px 2px transparent;
  }
  50% {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    box-shadow: 0px 0px 8px 2px transparent;
  }
  0% {
    box-shadow: 0px 0px 8px 2px #fff;
  }
}
