.nothing {
  color: white;
  text-align: center;
}

.inner {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 35px;

  @media (max-width: 1365px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 22px;
    row-gap: 17px;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    gap: unset;
  }
}

.control {
  display: flex;
  align-items: center;
  margin-bottom: 50px;

  @media (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 30px;
  }
}

.search {
  margin-right: auto;
  width: min-content;
  min-width: 300px;

  @media (max-width: 1365px) {
    min-width: 180px;
  }

  @media (max-width: 767px) {
    margin-right: 0;
    margin-bottom: 15px;
    min-width: 100%;
  }
}

.contitue {
  width: auto;
  min-width: 200px;
  margin-right: 20px;

  @media (max-width: 767px) {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.clear {
  width: min-content;

  @media (max-width: 767px) {
  }
}
