.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  z-index: 2050;
  pointer-events: auto;
  display: flex;
  justify-content: center;
  background-color: rgba(black, 0.6);
  padding: 50px 0px;
}

.content {
  position: relative;
  width: min-content;
  height: min-content;
  margin: auto 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media(max-width: 767px) {
    width: auto;
    padding: 0  20px;
  }
}

.button {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 200;
  width: 14px;
  height: 14px;
  padding: 10px;
  box-sizing: content-box;
  transition: transform 0.5s ease;
  color: white;

  @media(max-width: 767px) {
    right: 25px;
  }

  &:hover {
    transform: rotate(90deg);
  }

  & > svg {
    width: 14px;
    height: 14px;
  }
}
