.promo {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(../../../../assets/images/main_page_background_desktop.webp);
  background-position: center;
  background-size: cover;

  @media (min-width: 1919px) and (min-resolution: 150dpi) {
    background-image: url(../../../../assets/images/main_page_background_desktop_4k.webp);
  }

  @media (max-width: 1365px) {
    background-image: url(../../../../assets/images/main_page_background_tablet_landscape.webp);
  }

  @media (max-width: 1365px) and (orientation: portrait) {
    background-image: url(../../../../assets/images/main_page_background_tablet_portrait.webp);
  }

  @media (max-width: 767px) {
    background-image: url(../../../../assets/images/main_page_background_mobile.webp);
  }
}

.links {
  position: absolute;
  left: 50%;
  bottom: 70px;
  transform: translateX(-50%);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  height: 185px;

  @media(max-width: 1367px) {
    height: 120px;
  }

  @media(max-width: 1365px) {
    bottom: 50px;
    height: 100px;
  }

  @media (max-width: 767px) {
    position: absolute;
    grid-template-columns: repeat(1, 1fr);
    height: 100px;
  }
}

.item {
  background-color: #fff;
  height: inherit;
}

.catalog {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  & > a {
    position: relative;
    display: flex;
    height: 100%;

    &:hover {
      .image {
        width: 0;
      }
    }

    @media(max-width: 1365px) {
      &:hover {
        .image {
          width: 100%;
        }
      }
    }

    & > h3 {
      flex: 1 1 auto;
      align-self: center;
      text-align: center;

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 23px;
      font-weight: 500;
      color: #00caff;
      transition: width 0.3s ease;
      height: 100%;

      @media(max-width: 1365px) {
        font-size: 18px;
      }

      @media (max-width: 767px) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        background-color: #00B1FF;
        width: min-content;
        padding: 10px 40px;
        border-radius: 20px;
        height: auto;
        box-shadow: 0 0 4px 1px white;
      }
    }
  }

  .image {
    flex: 0 1 auto;
    transition: width 0.3s ease;
    overflow: hidden;
    height: 100%;
    width: 100%;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      display: block;
      border-radius: 10px;
      transition: width 0.3s ease;
      aspect-ratio: 1 / 1;

      @media(max-width: 1365px) {
      }
    }
  }

  @media(max-width: 767px) {
    display: none;
  }
}

.sales {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  & > a {
    position: relative;
    display: flex;
    height: 100%;

    &:hover {
      .image {
        width: 0;
      }
    }

    @media(max-width: 1365px) {
      &:hover {
        .image {
          width: 100%;
        }
      }
    }

    & > h3 {
      flex: 1 1 auto;
      align-self: center;
      text-align: center;

      font-size: 23px;
      font-weight: 500;
      color: #00caff;
      transition: width 0.3s ease;

      @media(max-width: 1365px) {
        font-size: 18px;
      }

      @media (max-width: 767px) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        background-color: #00B1FF;
        width: min-content;
        padding: 10px 40px;
        border-radius: 20px;
        height: auto;
        box-shadow: 0 0 4px 1px white;
      }
    }
  }

  .image {
    flex: 0 1 auto;
    transition: width 0.3s ease;
    overflow: hidden;
    height: 100%;
    width: 100%;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      display: block;
      border-radius: 10px;
      transition: width 0.3s ease;
      aspect-ratio: 1 / 1;

      @media(max-width: 1365px) {
      }
    }
  }

  @media (max-width: 767px) {
    border-radius: 15px;
  }
}

.download {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.modal {
  background-color: #2B3446;
  padding: 50px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
}

.items {
  display: flex;
  flex-direction: column;
  gap: 20px;

  h2 {
    margin-bottom: 20px;
  }

  a {
    color: #fff;
    text-decoration: underline;
    font-size: 20px;
  }
}
