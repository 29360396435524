.period {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 60px;
  flex-wrap: wrap;

  & > li {
    width: auto;
    margin-right: 30px;
    margin-bottom: 7px;
    position: relative;

    &:not(:last-child) {
      &::after {
        content: '/';
        position: absolute;
        color: white;
        font-family: Stem;
        font-weight: 300;
        padding-left: 15px;

        top: 50%;
        transform: translateY(-55%);
      }
    }

    &:first-child {
      font-size: 14px;

      @media (max-width: 1365px) {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
        margin-right: 0;

        &::after {
          display: none;
        }
      }

      & > button {
        @media (max-width: 767px) {
          font-size: 16px;
        }
      }
    }

    & > button {
      font-size: 20px;
      font-weight: 300;
      color: white;
      width: auto;
      font-family: Stem;

      @media (max-width: 1365px) {
        font-size: 18px;
      }

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 1365px) {
    margin-bottom: 45px;
  }

  @media (max-width: 767px) {
    margin-bottom: 0;
  }
}

// wrapper
.newsSection {
  display: flex;
  flex-flow: column wrap;
}

// article
.newsItem {
  gap: 60px;
  display: flex;
  height: 350px;
  padding: 40px 0;
  position: relative;

  &:hover {
    .image {
      & > img {
        transform: scale(1.04);
      }
    }
  }

  &:not(:last-child) {
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      opacity: 0.5;
      background-color: #fff;

      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  &:last-child {
    padding-bottom: 0;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    height: unset;
    gap: 30px;
    padding-bottom: 0;
  }
}

.newsWrapper {
  flex: 1 1 50%;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}

.date {
  font-size: 14px;
  font-weight: 700;
  color: white;
  margin-bottom: 10px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -10px;

    width: 100%;
    height: 1px;
    background-color: #fff;
    opacity: 0.3;

    @media(max-width: 767px) {
      bottom: 0;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 0;
    padding-bottom: 10px;
  }
}

.articleTitle {
  font-size: 20px;
  font-weight: 500;
  margin: 20px 0 0;

  @media (max-width: 1365px) {
    font-size: 18px;
    margin-bottom: 4px;
  }

  @media (max-width: 767px) {
    margin-top: 10px;
    font-size: 17px;
    margin-bottom: 11px;
  }
}

.description {
  align-self: center;

  color: #fff;
  font-size: 15px;
  font-weight: 300;
  line-height: 1.4;

  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (max-width: 1365px) {
    font-size: 14px;
  }
}

.moreInfo {
  align-self: flex-end;
  margin-top: auto;

  @media (max-width: 767px) {
    padding-top: 15px;
  }
}

.image {
  width: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 15px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transform: scale(1);
    transition: transform .6s ease-in-out;
  }

  @media (max-width: 767px) {
    flex: unset;
    width: 100%;
    aspect-ratio: 1 / 1;
  }
}

.yearFilter {
  position: relative;

  &::after {
    content: '';
    width: 0%;
    height: 1px;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: width 0.3s ease;
  }

  &.active {
    &::after {
      width: 100%;
    }
  }
}
