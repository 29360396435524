*,
html,
body {
  width: 100%;
  border: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

div,
span,
object,
iframe,
img,
table,
caption,
thead,
tbody,
tfoot,
tr,
tr,
td,
article,
aside,
canvas,
details,
figure,
hgroup,
menu,
nav,
footer,
header,
section,
summary,
mark,
audio,
video {
  border: 0;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
address,
code,
del,
dfn,
em,
ins,
q,
samp,
small,
strong,
sub,
sup,
b,
i,
hr,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
legend,
label {
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}

div,
main,
article,
aside,
canvas,
figure,
figure img,
figcaption,
hgroup,
footer,
header,
nav,
section,
audio,
video {
  display: block;
}

table {
  border-collapse: separate;
  border-spacing: 0;
}

table caption,
table th,
table td {
  text-align: left;
  vertical-align: middle;
}

a img {
  border: 0;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  line-height: 1;
}
li {
  line-height: 1;
}

:focus {
  outline: 0;
}

*,
:after,
:before {
  box-sizing: border-box;
}

button,
a {
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

label {
  -webkit-tap-highlight-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
