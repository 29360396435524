.title {
  margin-bottom: 50px;
}

.filters {
  display: flex;
  width: 100% ;
  gap: 20px;

  & > button {
    &:not(:last-child) {
      margin-right: 20px;
    }

    &:last-child {
      margin-left: auto;
    }
  }

  .filter,
  .clear,
  .difference{
    width: min-content;
  }

  .difference {
    & > button {
      font-weight: 500;
    }
  }

  .clear {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: 767px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 16px;

    .filter {
      grid-row: 1/2;
      grid-column: 1/2;
    }

    .difference {
      grid-row: 1/2;
      grid-column: 2/3;
      width: min-content;
      margin-left: auto;

    }

    .clear {
      grid-row: 2/3;
      grid-column: 2/3;
      width: min-content;
      margin-left: auto;
    }
  }
}

.wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 20px;
  padding-top: 5px;
  margin-top: 70px;

  @media (max-width: 1365px) {
    margin-top: 64px;
  }

  @media (max-width: 767px) {
    margin-top: 34px;
  }
}

.item {
  width: max-content;
  display: grid;
  column-gap: 20px;
  color: white;
  padding-top: 30px;
  padding-bottom: 20px;
  margin-bottom: 5px;
  position: relative;
  border-bottom: 1px solid rgba(white, .5);
  padding-right: 15px;

  &:before {
    content: attr(data-text);
    position: absolute;
    color: white;
    font-size: 12px;
    font-weight: 300;
  }
}

.card {
  display: grid;
  column-gap: 20px;
  position: relative;
  margin-bottom: 50px;
  width: max-content;
  padding-right: 15px;

  &::after {
    content: '';
    position: absolute;
    bottom: -40px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(#fff, .5);
  }
}

.nothing {
  text-align: center;
  color: white;
}
