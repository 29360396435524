.wrapper {
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    position: absolute;

    top: 50%;
    left: 65px;
    width: 19px;
    height: 18px;
    mask: url('../../../../assets/images/search.svg');
    -webkit-mask: url('../../../../assets/images/search.svg');
    background-color: #fff;

    transform: translateY(-50%);

    transition: top 0.3s ease, background-color 0.3s ease;
    pointer-events: none;

    @media(max-width: 1365px) {
      left: unset;
      right: 15px;
    }
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 42px;
    top: 5px;
    width: 1px;
    height: 19px;
    background-color: #fff;
    opacity: 0;

    transition: opacity 0.3s ease, background-color 0.3s ease;
  }

  &.active {
    &::after {
      left: 12px;
      pointer-events: none;
    }

    &::before {
      opacity: 1;
    }
  }

  &.black {
    &::before {
      background-color: black;
    }
    &::after {
      background-color: black;
    }
  }

  @media(max-width: 1365px) {
    &.active {
      &::after {
        display: none;
      }

      &::before {
        display: none;
      }
    }
  }

  @media(max-width: 767px) {
    height: 100%;
  }
}

.input {
  width: 100%;
  height: 29px;

  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 15px;

  padding-left: 56px;

  color: transparent;
  font-size: 14px;
  font-weight: 350;

  transition: border-color 0.3s ease, color .3s ease;

  &.active {
    color: white;

    &::placeholder {
      opacity: 1;
    }

    &.black {
      color: black;
    }
  }

  &.black {
    border-color: black;

    &::placeholder {
      color: black;
    }
  }

  &::placeholder {
    opacity: 0;
    transition: opacity 0.3s ease;

    color: #fff;
    font-size: 14px;
    font-weight: 350;
  }

  @media(max-width: 1365px) {
    padding-left: 15px;
  }

  @media(max-width: 767px) {
    height: 100%;
  }
}

.buttonSearch {
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;

  margin: auto 0;
  pointer-events: none;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  transition: opacity 0.4s ease;
  opacity: 0;

  &.active {
    opacity: 1;
    pointer-events: all;
  }
}
