.background {
  display: block;
  position: absolute;
  width: 337px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 0px 20px 0px rgba(black, 0.2);
  padding: 10px 19px 20px 19px;

  z-index: 2;
  top: 50px;

  @media (max-width: 1365px) {
    width: 100%;
  }
}

.filter {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.choice {
  text-align: left;
  display: flex;
  position: relative;
  padding-left: 25px;
  padding-bottom: 5px;
  transition: background-color 0.1s ease;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top:50%;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
    background-color: #fff;
    border: 1px solid rgba(black, 0.5);
  }

  &::after {
    content: '✓';
    position: absolute;
    font-size: 15px;
    font-weight: 900;
    line-height: 1;
    color: #00b1ff;
    left: 3px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover {
    background-color: rgba(black, 0.059);
  }

  &.active {
    &::after {
      opacity: 1;
    }
  }
  @media (max-width: 1365px) {
    margin-bottom: 8px;
  }
}
