.wrapper {
  display: flex;
  gap: 29px;
}

.image {
  width: 100%;
  max-width: 267px;
  aspect-ratio: 1 / 1;
  padding: 10px;

  @media (max-width: 1365px) {
    max-width: none;
  }

  @media(max-width: 767px) {
    padding: 0;
  }
}

.modal {
  position: fixed;
  z-index: 1000;

  img {
    width: 80vw;
    max-width: 80vw;
    max-height: 80vh;

    @media (max-width: 767px) {
      max-width: 60vw;
    }
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.prev {
  position: fixed;
  display: block;
  width: min-content;
  left: 10px;
  top: 50%;

  @media (max-width: 767px) {
    width: 40px;
    height: 40px;

    svg {
      height: 100%;
      width: 100%;
    }
  }
}

.next {
  position: fixed;
  display: block;
  width: min-content;
  right: 10px;
  top: 50%;

  @media (max-width: 767px) {
    width: 40px;
    height: 40px;

    svg {
      height: 100%;
      width: 100%;
    }
  }
}
