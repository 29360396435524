.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;

  &.white {
    .prev, .next {

      &:not(.deactivate) {
        filter: brightness(.5);

        &:hover {
          filter: brightness(.1);
        }
      }

      &.deactivate {
        filter: brightness(.8);
        cursor: auto;
      }
    }
  }

  &.black {
    .prev, .next {
      &:not(.deactivate) {
        filter: contrast(.8);

        &:hover {
          filter: contrast(1);
        }
      }

      &.deactivate {
        filter: contrast(1) opacity(.25);
        cursor: auto;
      }
    }
  }
}

.prev, .next {
  position: absolute;
  top: 50%;
  height: 64px;
  width: 64px;
  cursor: pointer;
  transition: 0.3s ease opacity, 0.3s ease filter;
  border-radius: 100%;
  transform: translateY(-50%);
  z-index: 10;

  svg {
    width: 100%;
    height: 100%;

    @media(max-width: 767px) {
      path {
        stroke-width: 3;
        stroke: rgb(219, 219, 219);
      }
    }
  }

  @media(max-width: 1365px) {
    width: 32px;
    height: 32px;
  }

  @media (max-width: 767px) {
    width: 25px;
    height: 25px;
  }
}

.next {
  right: -80px;

  @media (max-width: 1365px) {
    right: -40px;
  }

  @media (max-width: 767px) {
    right: 10px;
  }
}

.prev {
  left: -80px;

  @media (max-width: 1365px) {
    left: -40px;
  }

  @media (max-width: 767px) {
    left: 10px;
  }
}

svg {
  width: 64px;
  height: 64px;
}

// .deactivate {
//   filter: contrast(0.00001);
// }
