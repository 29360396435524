.button {
  background-color: #00b1ff;
  border-radius: 20px;
  padding: 11px 30px 10px;
  box-shadow: 0px 0px 3px 2px #fff;

  white-space: nowrap;
  width: min-content;

  transition: box-shadow 0.3s ease, background-color 0.3s ease, color 0.3s ease;

  color: #fff;
  font-family: Stem;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  & > svg {
    max-height: 18px;
    max-width: 20px;
  }

  &:hover {
    box-shadow: 0px 0px 5px 4px #fff;
  }

  &.small {
    font-weight: 300;
    padding: 2px 15px 3px;
    text-transform: lowercase;
    box-shadow: 0px 0px 0px 0px #fff;

    &:hover {
      box-shadow: 0px 0px 4px 0px #fff;
    }
  }

  &.readMore {
    box-shadow: 0px 0px 3px 2px rgba(black, 0);
    width: 100%;
    text-align: center;

    &:hover {
      box-shadow: 0px 0px 3px 2px rgba(black, 0.2);
    }
  }

  &.diller {
    align-self: flex-end;
  }

  &.news {
    @media (max-width: 767px) {
      padding: 5px 15px 5px;
    }
  }

  &.coparison {
    @media (max-width: 767px) {
      color: #fff;
      font-family: Stem;
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
      padding: 11px 10px 10px;
    }
  }

  &.cross {
    box-shadow: unset;
    background-color: unset;
    padding: 10px;
    position: relative;
    transition: transform 0.3s ease;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 15px;
      height: 2px;
      background-color: #00b1ff;
      transform: translate(-50%, -50%);
      transition: transform 0.3s ease;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    &:hover {
      transform: rotate(45deg);
    }

    &.pressed {
      transform: rotate(45deg);

      &::before {
        transform: translate(-50%, -50%) rotate(135deg);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  &.mini {
    padding: 8px 12px;
    border-radius: 50%;

    &:hover {
      box-shadow: 0px 0px 3px 3px #fff;
    }
  }

  &.transparent {
    background: none;
    box-shadow: none;
    border: 1px solid #fff;

    &:hover {
      box-shadow: 0px 0px 3px 3px #fff;
    }
  }

  &.blackTransparent {
    width: 100%;
    background: none;
    box-shadow: none;
    border: 1px solid #000;
    color: #000;

    &:hover {
      box-shadow: 0px 0px 8px 0px rgba(black, .3);
    }
  }
  .question {
  }

  &[disabled] {
    color: rgb(193, 193, 193);
    background-color: #00b1ff;
    box-shadow: 0px 0px 0px 0px #fff;
  }
}
