.socials {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ok {
  width: 17px;
  height: 29px;

  margin-right: 19px;
}
.vk {
  display: block;
  width: 27px;
  height: 15px;
}
img {
  width: 100%;
  height: 100%;
}
