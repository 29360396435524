.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 1365px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.title {
  color: black;
  grid-row: 2/3;
  grid-column: 1/3;

  @media (max-width: 1365px) {
    grid-column: 1/2;
  }
}

.desc {
  grid-area: 4/2/5/3;

  @media (max-width: 1365px) {
    grid-row: 5/6;
    grid-column: 1/2;
  }
}

.image {
  grid-area: 4/1/5/2;
  width: 100%;

  @media(max-width: 767px) {
    display: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top;
  }
}

.desc_title {
  grid-area: 1/2/2/3;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.1;
  padding-bottom: 15px;
}

.desc_text {
  grid-area: 2/2/3/3;
  white-space: pre-wrap;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.3;

  @media(max-width: 767px) {
    line-height: 1.4;
    margin-bottom: 15px;
  }
}

.subcategories {
  grid-area: 3/1/4/3;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 35px;
  row-gap: 40px;

  margin-bottom: 80px;

  @media (max-width: 1365px) {
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 50px;
  }

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);

    grid-auto-rows: unset;
    column-gap: 20px;
    row-gap: 20px;
  }

  @media (max-width: 767px) {
    gap: 15px;
    margin-bottom: 30px;
  }
}

.products {
  grid-area: 5/1/6/3;

  @media (max-width: 1365px) {
    grid-row: 6/7;
    grid-column: 1/2;
  }
}
