.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .navItem {
    display: inline;
    width: min-content;
    margin-right: 22px;
  }
}
