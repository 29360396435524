#main_page_partners {
  .slick-slider {
    overflow: hidden;
  }
  .slick-slide {
    padding-right: 39px;
  }
  .slick-list {
    width: calc(100% + 39px);
  }
  .slick-track {
    display: flex;
    align-items: center;
  }
}

#main_page_news {
  .slick-slider {
    overflow: hidden;
  }
  .slick-slide {
    padding-right: 39px;
  }
  .slick-list {
    width: calc(100% + 39px);
  }
}

#laboratorySection {
  .slick-track {
    display: flex;
    align-items: flex-start;
    column-gap: 20px;

    @media(max-width: 767px) {
      align-items: flex-end;
      column-gap: 0;
    }
  }
  .slick-slide {
    // padding-right: 20px;
  }
}

#main_page_projects {
  .slick-track {
    display: flex;
    align-items: flex-start;
    column-gap: 20px;

    @media(max-width: 767px) {
      align-items: flex-end;
      column-gap: 0;
    }
  }

  .slick-slider {
    overflow: hidden;
  }

  .slick-list {
    // width: calc(100% + 39px);

    @media(max-width: 767px) {
      width: 100%;
    }
  }

  // .slick-slide {
  //   padding-right: 39px;

  //   @media(max-width: 1365px) {
  //     padding-right: 20px;
  //   }
  // }
}

.slick-slider {
}

.slick-track {
  display: flex !important;
  align-items: stretch;
}

.slick-slide {
  min-height: 100%;
  height: inherit;
  float: unset;

  & > div {
    height: inherit;
    min-height: 100%;

    width: 100%;
  }
}
