.wrapper {
    padding-top: 39px;
    padding-bottom: 68px;
}

.title {
    color: #000;
    font-family: Stem;
    font-size: 42px;
    font-weight: 500;
    padding-bottom: 39px;

    @media (max-width: 1365px) {
        font-size: 24px;
        padding-bottom: 30px;
    }

    @media (max-width: 767px) {
        font-size: 16px;
        white-space: inherit;
        width: 100%;
        padding-bottom: 20px;
    }
}

.products {
    display: flex;
    gap: 33px;
}

.product {
    padding: 20px;
}

.inner {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 35px;
    row-gap: 35px;

    @media (max-width: 1365px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
}