.wrapper {
  overflow-x: hidden;
  overflow-y: hidden;
  border: 2px solid black;
  margin-top: 40px;

  @media(max-width: 1190px) {
    overflow-x: scroll;
  }
}

.table {
  display: grid;
  grid-template-columns: repeat(12, auto);
}

.table_header_item {
  border: 2px solid black;
  padding: 5px;
  align-self: center;
  text-align: center;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  background-color: #e6e6e6;

  & > svg {
    width: 20px;
    max-height: 20px;

    path {
      fill: black;
    }
  }
}

.table_body_item {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  padding: 1px 5px;
  white-space: pre;
  font-size: 14px;

  & > a {
    display: flex;
    justify-content: center;
    color: #00B1FF;
  }
}

.ledBrand {
  outline: 1px solid transparent;
}
.optic {
  outline: 3px solid transparent;
}
.info {}
.row {}
.title {
  outline: 1px solid transparent;
}
.lightOutput {
  outline: 1px solid transparent;
}
.opt {
  outline: 1px solid transparent;
}
.title {}
