.wrapper {
  display: flex;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  .title {
    flex: 0 0 20%;

    color: #fff;
    font-size: 25px;
    font-weight: 500;
    line-height: normal;

    @media(max-width: 1365px) {
      font-size: 22px;
    }

    @media (max-width: 767px) {
      font-size: 20px;
      margin-bottom: 30px;
    }
  }

  .text {
    flex: 0 1 80%;

    &__title {
      margin-bottom: 20px;

      color: #fff;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.3; /* 19.53px */

      @media(max-width: 1365px) {
        font-size: 16px;
      }

      @media(max-width: 767px) {
        font-size: 14px;
      }
    }

    &__description {
      color: #fff;
      font-size: 18px;
      font-weight: 300;
      line-height: 1.3; /* 19.53px */


      @media(max-width: 1365px) {
        font-size: 16px;
      }

      @media(max-width: 767px) {
        font-size: 14px;
      }

      & > p {
        margin-bottom: 14px;
      }
    }
  }
}
