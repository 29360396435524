.wrapper {
  width: 100%;
  display: block;
  height: 100%;
}

.image {
  width: 100%;
  aspect-ratio: 1 / 1;
  background-color: #fff;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 74px;
  padding: 10px;

  color: #000;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;

  @media(max-width: 767px) {
    font-size: 16px;
    text-align: center;
  }
}
