.image {
  height: 500px;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: 1365px) {
    height: 402px;
  }
  @media (max-width: 767px) {
    height: 157px;
  }
}
