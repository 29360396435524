.wrapper {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  // width: min-content;

  @media (max-width: 767px) {
    display: flex;
  }
}

.title {
  margin-bottom: 4px;
  // width: min-content;
  white-space: nowrap;

  color: #fff;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.3;

  @media (max-width: 767px) {
    &::after {
      content: ': ';
    }
  }
}

.text {
  display: block;

  white-space: nowrap;
  width: min-content;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;

  @media (max-width: 1365px) {
    white-space: pre;
  }

  @media (max-width: 767px) {
    white-space: pre;
    width: max-content;
  }
}
