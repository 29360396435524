html,
body {
  scrollbar-gutter: stable;
}

body {
  height: 100%;
  font-family: 'Stem';
  background-image: linear-gradient(
    rgba(55, 201, 240, 1) 0px,
    rgba(52, 161, 194, 1) 500px,
    rgba(50, 121, 148, 1) 1000px,
    rgba(48, 90, 112, 1),
    rgba(46, 68, 86, 1),
    rgba(45, 50, 65, 1),
    rgba(45, 55, 70, 1));
  background-repeat: no-repeat;
  background-color: #2e3343;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}

main {
  flex: 1 1 auto;
  padding-bottom: 100px;
  background-color: transparent;
  transition: background-color 0.3s ease;

  &.whiteBackground {
    background-color: #fff;
  }

  @media print {
    padding-top: 20px !important;
  }

  @media(max-width: 767px) {
    padding-bottom: 30px;
  }
}

// custom scrollbar
::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #2e3343;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #00b1ff;
  width: 3px;
  border-radius: 5px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0075a7;
}

h1 {
  color: #fff;
  font-family: Stem;
  font-size: 43px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  margin-bottom: 78px;

  @media (max-width: 1365px) {
    margin-bottom: 63px;
    font-size: 36px;
  }

  @media (max-width: 767px) {
    font-size: 26px;
    margin-bottom: 28px;
  }
}

h2 {
  color: #fff;
  font-family: Stem;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  margin-bottom: 40px;

  @media(max-width: 1365px) {
    font-size: 34px;
    margin-bottom: 30px;
  }

  @media (max-width: 767px) {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.visually-hidden {
  position: absolute !important;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.page_container {
  width: 100%;
  max-width: 1260px;
  position: relative;

  margin: 0 auto;
  padding: 0 60px;

  @media(max-width: 1365px) {
    padding: 0 40px;
  }

  @media(max-width: 1100px) {
    padding: 0 40px;
  }

  @media(max-width: 767px) {
    padding: 0 15px;
  }
}

.breadCrumbs {
  margin-bottom: 35px;

  @media (max-width: 1365px) {
    margin-bottom: 28px;
  }

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
}

.blogTable {
  display: flex !important;
  justify-content: flex-start;
  overflow-x: auto;
  position: relative;
  width: 100% !important;

  & > caption {
    display: none;
  }

  & > thead {
    display: none;
  }

  & > tbody {
    position: relative;
    width: min-content;

    & > tr {
      &:first-child {
        background-color: rgba(white, .1);
        font-weight: 500;

        & > td {
          text-align: center;
        }
      }

      & > td {
        &:first-child {
          font-weight: 500;
        }

        &:not(:first-child) {
          width: auto;
        }
      }
    }
  }
}
