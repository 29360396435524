
#factory {
    .slick-track {
        display: flex;
        align-items: flex-start;
    }
    .slick-slide {
        height: 100%;
    }
}

#team {
    .slick-track {
        display: flex;
        align-items: flex-start;
    }
    .slick-slide {
        height: 100%;
    }
}