.header {
  position: fixed;
  z-index: 1000;
  background-color: transparent;
  backdrop-filter: blur(4px);
  transition: background-color 0.3s ease;

  &.scroll {
    background-color: rgba(black, 0.1);
  }

  @media print {
    display: none;
  }
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-template-rows: 1fr;
  justify-content: space-between;
  align-items: center;

  position: relative;

  padding-top: 42px;
  padding-bottom: 49px;
  transition: padding 0.3s ease;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 120px);
    height: 1px;
    background-color: #fff;
    opacity: 1;
    transition: opacity 0.3s ease;

    @media (max-width: 1365px) {
      width: calc(100% - 90px);
    }

    @media (max-width: 767px) {
      width: calc(100% - 60px);
    }
  }

  &.black {
    &::after {
      background-color: rgba(black, 0.3);
    }
  }

  &.scroll {
    padding-top: 20px;
    padding-bottom: 20px;

    &::after {
      opacity: 0;
    }
  }

  @media(max-width: 1365px) {
    grid-template-columns: 1fr 1fr minmax(auto, 50%) 1fr 1fr;
    column-gap: 20px;

    padding-top: 45px;
    padding-bottom: 40px;

    &.scroll {
      padding-top: 35px;
      padding-bottom: 35px;
    }
  }

  @media(max-width: 767px) {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, auto);
    row-gap: 40px;
    padding-top: 20px;
    padding-bottom: 20px;

    &.scroll {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
}

.logo {
  width: 198px;
  display: inline-block;
  margin-right: 84px;

  &.black {
    & > svg {
      & > path {
        fill: black;
      }
    }
  }

  & > svg {
    width: 100%;
    height: 100%;
    object-fit: contain;

    & > path {
      fill: white;
      transition: fill 0.3s ease;
    }
  }

  @media(max-width: 1365px) {
    width: 150px;
    margin-right: 0;
    margin-bottom: -35px;
  }

  @media(max-width: 767px) {
    width: 140px;
    grid-area: 1/2/2/6;
    justify-self: center;
  }
}

// Catalog
.blue {
  border-radius: 15px;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
  color: white;

  background-color: #00b1ff;
  box-shadow: 0px 0px 2px 1px #fff;
  transition: box-shadow 0.3s ease;

  &.black {
    box-shadow: 0px 0px 3px 2px rgba(black, 0.1);

    &:hover {
      box-shadow: 0px 0px 3px 2px rgba(black, 0.2);
    }
  }

  &.hidden {
  }

  & > a {
    color: white;
    padding: 6px 19px 7px 20px;
    display: block;
  }

  &:hover {
    box-shadow: 0px 0px 3px 2px #fff;
  }

  &::after {
    display: none;
  }

  @media(max-width: 1365px) {
    &.hidden {
      display: none;
    }
  }

  @media(max-width: 767px) {
    grid-area: 2/1/3/4;
    width: 100%;
    min-width: auto;

    &.hidden {
      display: none;
    }
  }
}

// Menu
.nav {
  display: flex;
  align-items: center;
  width: 350px;
  padding: 7px 0 7px 2px;
  overflow: hidden;
  transition: width 0.3s ease;

  &.searchActive {
    width: 0px;
  }

  &.black {
    a {
      color: black;

      &::after {
        background-color: black;
        box-shadow: 0px 0px 2px 0px black;
      }
    }
  }

  @media(max-width: 1365px) {
    display: none;
  }
}

.search {
  width: 95px;
  transition: width 0.3s ease;
  position: relative;

  justify-content: flex-end;

  &.active {
    width: 442px;
  }

  @media(max-width: 1365px) {
    width: auto;

    &.active {
      grid-area: 1/2/2/4;
      width: auto;
    }
  }

  @media(max-width: 767px) {
    grid-area: 2/4/3/7;
    height: 100%;

    &.active {
      grid-area: 2/1/3/7;
      width: auto;
      height: 34px;
    }
  }
}

.icons {
  width: 236px;
  position: relative;
  right: 0;

  @media(max-width: 767px) {
    grid-area: 1/1/2/2;
    width: 100%;
  }
}

.burger {
  width: 19px;
  height: 20px;
  margin-right: 0;

  @media(max-width: 767px) {
    grid-area: 1/6/2/7;
    justify-self: flex-end;
  }
}
