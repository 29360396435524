.modal {
  // background-color: rgba(black, 0.8);
  width: 100%;
  height: 100%;
  min-height: 80vh;

  position: relative;

  & > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
