.wrapper {
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    flex-direction: column-reverse;
    gap: 8px;
    width: 100%;
  }
}

.image {
  width: 100%;
  aspect-ratio: 1 / 1;
  margin-bottom: 17px;
  overflow: hidden;
  border-radius: 10px;

  @media(max-width: 767px) {
    margin-bottom: 40px;
  }

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;

    filter: grayscale(80%) brightness(.8);
    transform: scale(1);
    transition: 0.4s ease filter, transform .7s ease-in-out;

    @media(max-width: 1365px) {
      filter: grayscale(0) brightness(1);
    }
  }

  &:hover {
    & > img {
      filter: grayscale(0%) brightness(1);
      transform: scale(1.03);
    }
  }
}

.text {
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.3;

  @media (max-width: 767px) {
    font-size: 16px;
    min-height: 30px;
  }
}
