.title {
  margin-bottom: 20px;

  color: #fff;
  font-size: 25px;
  font-weight: 500;
  line-height: normal;
}

.items {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
}
