.link {
  color: #fff;
  font-size: 14px;
  font-weight: 500;

  transition: box-shadow 0.3s ease;
  position: relative;
  overflow: visible;
  transition: color 0.3s ease;

  &::after {
    content: '';
    position: absolute;
    display: block !important;
    bottom: -3px;
    left: 0;
    opacity: 0;

    width: 0px;
    height: 1px;
    background-color: #fff;
    box-shadow: 0px 0px 2px 1px #fff;

    transition: width 0.3s ease, box-shadow 0.3s ease;
  }

  &.pressed {
    &::after {
      display: block !important;
      width: 100%;
      opacity: 1;
    }
  }

  &:hover {
    &::after {
      width: 100%;
      opacity: 0.6;
      box-shadow: 0px 0px 2px 0px #fff;
      // transition: box-shadow 0.3s ease;
      display: block !important;
    }
  }
}
