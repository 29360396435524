.items {
  display: flex;
  gap: 34px;

  .item {
    padding-left: 10px;
    padding-right: 10px;

    @media(max-width: 767px) {
      padding-left: 5px;
      padding-right: 5px;
    }

    .itemPhoto {
      width: 100%;
      aspect-ratio: 1 / 1;
      margin-bottom: 5px;

      @media(max-width: 767px) {
        margin-bottom: 10px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        border-radius: 10px;
        display: block;
      }
    }

    .itemFio {
      margin-bottom: 5px;

      color: #fff;
      font-size: 18px;
      font-weight: 500;
      line-height: normal;

      @media(max-width: 767px) {
        font-size: 15px;
      }
    }

    .itemPosition {
      color: #fff;
      font-size: 14px;
      font-weight: 300;
      line-height: normal;
    }
  }
}

.separator {
  width: 100%;
  height: 1px;

  margin-bottom: 50px;

  background-color: #fff;

  @media (max-width: 1365px) {
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    margin-bottom: 17px;
  }
}
