.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  .image {
    width: 100%;
    aspect-ratio: 1 / 1;
    position: relative;
    background-color: #fff;

    img {
      object-fit: contain;
      display: block;
    }
  }

  .labelNew {
    position: absolute;
    top: 10px;
    left: -30px;
    width: 100px;
    background-color: red;
    text-align: center;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    transform: rotate(-45deg);
  }

  .deleteButton {
    position: absolute;
    top: 0;
    right: 0;
    width: max-content;
    padding: 10px;

    & > svg {
      width: 20px;
      height: 20px;
      transition: transform .3s ease;
    }

    &:hover {
      & > svg {
        width: 20px;
        height: 20px;
        transform: rotate(90deg);
      }
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;

    padding: 10px 20px 22px 20px;
    flex-shrink: 100;

    & > a {
      grid-area: 4/1/5/2;
      align-self: flex-start;
      justify-self: flex-start;
      width: min-content;
      height: min-content;

      box-shadow: 0px 0px 3px 2px rgba(black, 0);

      &:hover {
        box-shadow: 0px 0px 3px 2px rgba(black, 0.2);
      }
    }
  }

  .subcategory {
    margin-bottom: 8px;

    color: #000;
    font-size: 14px;
    font-weight: 300;
  }

  .title {
    margin-bottom: auto;
    color: #000;
    font-size: 20px;
    font-weight: 500;
    line-height: 1em;
    min-height: 2em;

    @media(max-width: 1365px) {
      font-size: 18px;
    }

    @media(max-width: 767px) {
      font-size: 16px;
      line-height: 1;
      display: block;
      width: 100% !important;
      white-space: normal;
      line-height: unset;
      min-height: unset;

      &:hover {
        box-shadow: unset !important;
      }
    }
  }

  .priceWrapper {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .price {
    margin-bottom: 15px;
    color: #00b1ff;
    font-size: 16px;
    font-weight: 500;

    display: flex;
    justify-content: space-between;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    &:last-of-type {
      margin-bottom: 12px;
    }

    .typePrice {
      width: auto;
      letter-spacing: -0.5px;
      line-height: 1.1;

      @media(max-width: 1365px) {
      }

      @media(max-width: 767px) {
        font-size: 12px;
      }
    }

    .cost {
      text-align: right;
      width: min-content;
      white-space: nowrap;
      padding-left: 10px;
      letter-spacing: -0.01em;
      word-spacing: -0.18em;

      @media(max-width: 767px) {
        font-size: 12px;
        white-space: wrap;
      }
    }

    .old {
      width: min-content;
      margin-left: 20px;
      font-weight: 300;
      color: grey;
      position: relative;
      white-space: nowrap;

      @media(max-width: 767px) {
        font-size: 12px;
      }

      &::before {
        content: '';
        position: absolute;
        right: -10%;
        top: 45%;
        width: 120%;
        height: 1px;
        background-color: gray;

        transform: rotate(-10deg);
      }
    }

    &.action {
      position: relative;

      .cost {
        margin-left: auto;
      }
    }
  }

  .links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;

    & > a {
      margin-bottom: 0;
    }
  }

  .like {
    height: min-content;
    display: flex;
    justify-content: flex-end;

    & > svg {
      max-width: 23px;
      max-height: 20px;

      & > path {
        &:nth-child(2) {
          opacity: 0;
        }
      }
    }

    &.active {
      & > svg {
        & > path {
          &:nth-child(2) {
            opacity: 1;
          }
        }
      }
    }
  }

  .card {
    grid-area: 4/3/5/4;
    align-self: center;
    height: min-content;

    & > svg {
      max-width: 23px;
      height: min-content;
    }
  }

  .moreInfo {
    display: none;
  }

  &.list {
    display: flex;
    flex-direction: row;

    .image {
      width: 300px;
      height: 200px;

      @media(max-width: 1365px) {
        height: 150px;
        width: 200px;
      }

      @media(max-width: 767px) {
        width: 90px;
        height: 90px;
      }
    }

    .text {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;
      margin: auto 0;
      padding-bottom: 10px;

      @media(max-width: 1365px) {
        display: grid;
        grid-template-columns: 1fr 2fr;
        column-gap: 0;
      }

      @media(max-width: 767px) {
        grid-template-columns: 1fr;
        justify-content: unset;
        row-gap: 8px;
        padding: 5px;
      }
    }

    .subcategory {
      display: none;
    }

    .title {
      margin-top: unset;
      margin-bottom: unset;

      @media(max-width: 1365px) {
        grid-area: 1/1/3/2;
      }

      @media(max-width: 767px) {
        grid-area: 1/1/2/2;
        display: block;
        width: 100%;
        height: 100%;
        text-align: center;
      }
    }

    .priceWrapper {
      flex-direction: row;
      gap: 20px;

      @media(max-width: 1365px) {
        gap: 10px;
        grid-area: 1/2/2/3;
      }

      @media(max-width: 767px) {
        grid-area: 2/1/3/2;

      }
    }

    .price {
      display: flex;
      flex-direction: column;
      align-items: center ;
      margin-top: unset;
      margin-bottom: unset;
      white-space: nowrap;

      .typePrice {
        text-align: center;
        margin-bottom: 10px;

        @media(max-width: 767px) {
          margin-bottom: 5px;
          white-space: normal;

        }
      }

      .cost {
        align-self: center;
        width: min-content;
        padding-left: 0;

        @media(max-width: 767px) {
          white-space: normal;
          text-align: left;
          width: 100%;
          display: block;
          text-align: center;
        }
      }

      @media(max-width: 767px) {
        white-space: normal;
      }

      .old {
        margin-left: 0;
      }

      &.action {
        @media(max-width: 767px) {
          padding-top: 7px;
        }

        .typePrice {
          order: 1;
        }

        .cost {
          margin-left: 0;
          order: 3;
          margin-top: 5px;
        }

        .old {
          order: 2;
        }
      }
    }

    .links {
      justify-content: flex-end;

      .like {
        width: min-content;
        margin-left: 20px;
      }

      @media(max-width: 1365px) {
        grid-area: 2/2/3/3;
      }

      @media(max-width: 767px) {
        display: none;
      }
    }
  }

  &.more {
    display: flex;
    flex-direction: row;

    @media(max-width: 767px) {
      flex-direction: column;
    }

    .image {
      width: 500px;
      height: 100%;

      @media(max-width: 1365px) {
        width: 300px;
      }

      @media(max-width: 767px) {
        width: auto;
        height: auto;
      }
    }

    .text {
      display: grid;
      grid-template-columns: auto auto;
      column-gap: 15px;
      justify-content: unset;

      @media(max-width: 767px) {
        grid-template-columns: auto;
        padding-bottom: 15px;
        column-gap: unset;
      }
    }

    .subcategory {
      display: none;
    }

    .priceWrapper {
      grid-area: 2/2/3/3;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      @media(max-width: 767px) {
        grid-area: 3/1/4/2;
        margin-bottom: 15px;
      }

      .price {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .typePrice {
        text-align: center;
        white-space: nowrap;
        margin-right: 8px;

        &::after {
          content: ':';
        }
      }

      .cost {
        width: min-content;
        padding-left: 0;
      }
    }

    .title {
      grid-area: 1/1/2/3;
      text-align: center;
      margin-bottom: 10px;

      @media(max-width: 767px) {
        grid-area: 1/1/2/2;
        margin-top: 5px;
        margin-bottom: 15px;
      }
    }

    .links {
      grid-area: 3/2/4/3;
      display: flex;
      align-items: flex-end;
      width: 100%;

      @media(max-width: 767px) {
        grid-area: 4/1/5/2;
        align-items: center;
      }

      .like {
        margin-left: auto;
        width: 100%;
      }
    }

    .moreInfo {
      grid-area: 2/1/4/2;
      display: block;
      flex-wrap: wrap;
      flex-direction: column;
      margin-bottom: 0;

      @media(max-width: 767px) {
        grid-area: 2/1/3/2;
        margin-bottom: 15px;
      }

      .moreInfoItem {
        display: flex;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .moreInfoTitle {
        width: min-content;
        white-space: normal;
        margin-right: 8px;
        color: #666;

        @media(max-width: 767px) {
          font-size: 12px;
          white-space: nowrap;
        }
      }

      .moreInfoValue {
        @media(max-width: 767px) {
          font-size: 12px;
        }
      }
    }
  }
}
