.wrapper {
  display: flex;

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.title {
  position: relative;
  margin-right: 97px;
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  line-height: normal;

  @media (max-width: 1365px) {
    margin-right: 18px;
    font-size: 17px;
  }

  @media (max-width: 767px) {
    margin-bottom: 7px;
    font-weight: 500;
    line-height: 1.2;
  }
}

.image {
  width: 396px;
  height: 172px;
  overflow: hidden;
  border-radius: 15px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
}
