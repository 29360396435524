.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 50px;
  width: 100%;

  @media (max-width: 1365px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.title {
  color: #00b1ff;
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 10px;
  position: relative;

  @media (max-width: 1365px) {
    &::after {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: rgba(black, 0.3);

      @media (max-width: 767px) {
        display: none;
      }
    }
  }
}

.slider {
  max-width: 100%;
  overflow: hidden;
  cursor: grab;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 1365px) {
    grid-row: 2/3;
  }
}

.singleSlider {
  margin-bottom: 10px;

  img {
    object-fit: cover;
    display: block !important;
  }
}

.multipleSlider {
  & > div {
    & > div {
      height: 120px;
    }
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
    display: block !important;
  }
}

.info {
  @media (max-width: 1365px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    margin-bottom: 30px;

    .title {
      grid-row: 1/2;
      grid-column: 1/2;
    }

    .info_list {
      grid-row: 1/3;
      grid-column: 2/3;
    }

    .price {
      grid-row: 2/3;
      grid-column: 1/2;
    }

    .feedback {
      grid-area: 3/1/4/3;
    }
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
}

.info_list {
  margin-bottom: 65px;
  position: relative;

  @media (max-width: 1365px) {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -32px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(black, 0.3);

    @media (max-width: 1365px) {
      display: none;
    }

    @media (max-width: 767px) {
      display: block;
      bottom: 0;
    }
  }
}

.info_item {
  padding: 5px 0;

  & > dt {
    font-weight: 300;
    font-size: 18px;
    line-height: 1.3;
    display: inline;
    padding-right: 10px;
  }

  & > dd {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.3;
    display: inline;
    width: unset;
    width: 100%;
  }

  &.led_brand {
    display: flex;
    & > dt {
      display: flex;
      align-items: center;
    }
    select {
      height: 37px;
      border-radius: 20px;
      text-align: center;
    }
  }
}

.feedback {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.whatsapp {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  @media(max-width: 767px) {
    align-items: center;
  }

  & > a {
    display: flex;
    align-items: center;

    & > span {
      font-weight: 300;
      font-size: 18px;
      line-height: 1.3;
      display: inline;
      padding-left: 10px;
      color: black;

      @media(max-width: 767px) {
        font-size: 16px;
        padding-left: 5px;
      }
    }
  }

  svg {
    color: #25D366;
    width: 40px;
    height: 40px;

    @media(max-width: 767px) {
      width: 30px;
      height: 30px;
    }
  }
}

.form {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

