.image {
  height: 346px;
  margin-bottom: 25px;
  border-radius: 10px;
  overflow: hidden;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;

    filter: grayscale(80%) brightness(.8);
    transform: scale(1);
    transition: 0.4s ease filter, transform .7s ease-in-out;

    @media(max-width: 1365px) {
      filter: grayscale(0) brightness(1);
    }
  }

  &:hover {
    & > img {
      filter: grayscale(0%) brightness(1);
      transform: scale(1.03);
    }
  }

  @media (max-width: 767px) {
    height: unset;
    width: 100%;
    aspect-ratio: 1 / 1;
  }
}

.address {
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
}

.wrapper {
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}