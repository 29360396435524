.image {
  max-width: 207px;
  @media (max-width: 1365px) {
    max-width: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
