.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 54px;
  background-color: #2e3343;

  @media print {
    display: none;
  }

  @media (max-width: 767px) {
    padding-top: 50px;
  }
}

.upperLine {
  display: flex;
  justify-content: space-between;
  margin-bottom: 114px;

  @media(max-width: 1365px) {
    margin-bottom: 60px;
  }

  .logo {
    width: 200px;
    height: 33px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @media (max-width: 1365px) {
      margin-right: 15px;
    }
  }

  .nav {
    width: 771px;
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.centerLine {
  display: flex;
  justify-content: space-between;
  row-gap: 10px;
  margin-bottom: 40px;

  @media(max-width: 1365px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 30px;
    column-gap: 30px;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
  }

  @media(max-width: 500px) {
    grid-template-columns: 1fr;
    row-gap: 30px;
  }

  .contacts {
    display: inline-block;
    width: min-content;
    white-space: nowrap;

    @media(max-width: 1365px) {
      display: none;
    }
  }

  .buttons {
    width: 231px;

    @media (max-width: 1365px) {
      grid-area: 1/1/2/2;
      justify-self: center;
      margin-right: 0;
      width: 100%;
    }

    @media(max-width: 500px) {
      grid-area: 1/1/2/2;
    }
  }

  .contactLinks {
    width: min-content;

    @media(max-width: 1365px) {
      grid-area: 2/1/3/2;
      justify-self: center;
      margin-right: 0;
    }

    @media(max-width: 500px) {
      grid-area: 2/1/3/2;
    }
  }

  .partner {
    width: 188px;

    @media (max-width: 1365px) {
      grid-area: 1/2/3/3;
      justify-self: right;
    }

    @media (max-width: 767px) {
      grid-area: 1/2/2/3;
    }

    @media(max-width: 500px) {
      grid-area: 3/1/4/2;
      justify-self: center;
    }

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: top;
      display: block;
    }
  }

  .partner_msp {
    width: 188px;

    & > a {
      display: block;

      & > img {
        display: block;
      }
    }

    @media (max-width: 1365px) {
      grid-area: 1/3/3/4;
      justify-self: right;
    }

    @media (max-width: 767px) {
      grid-area: 2/2/3/3;
    }

    @media(max-width: 500px) {
      grid-area: 4/1/5/2;
      justify-self: center;
    }
  }

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top;
    display: block;
  }
}

.separator {
  width: 100%;
  height: 1px;
  margin-bottom: 30px;
  background-color: #fff;
}

.lowerLine {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  .confidential,
  .developed {
    width: min-content;

    color: #fff;
    font-size: 13px;
    font-weight: 300;
    line-height: normal;
  }
}
