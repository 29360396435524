.wrapper {
  background-color: #2b3446;
  width: 100%;
  max-width: 770px;
  min-width: 500px;
  margin: auto;
  border-radius: 15px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 60px 50px;

  & > div:first-of-type {
    & > div {
      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }
  }

  & > button {
    align-self: center;
  }

  @media (max-width: 1365px) {
    max-width: 490px;
    min-width: 450px;
    padding: 37px 54px;
  }

  @media (max-width: 767px) {
    max-width: 468px;
    min-width: unset;
    padding: 18px 25px 40px;
  }
}

.title {
  color: white;
  text-align: center;
  font-size: 34px;
  font-weight: 500;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    font-size: 24px;
  }
}

.agree {
  display: flex;
  align-items: center;

  @media (max-width: 1365px) {
    font-size: 15px;
  }

  @media (max-width: 767px) {
    font-size: 14px;
  }
}

.required_fields {
  color: white;
  font-size: 20px;
  font-weight: 500;
  margin: 15px 0;
  text-align: center;

  @media (max-width: 1365px) {
    font-size: 15px;
  }

  @media (max-width: 767px) {
    font-size: 14px;
  }
}

.text {
  margin-bottom: 0 !important;
}

.link {
  display: none;
}

.success_sending {
  width: 100%;
  max-width: 600px;
  background-color: #2b3446f2;
  padding: 50px 120px;
  border-radius: 15px;

  & > p {
    color: white;
    text-align: center;

    font-size: 42px;
    font-weight: 500;
    letter-spacing: 2.1px;

    @media (max-width: 1365px) {
      font-size: 25px;
    }

    @media (max-width: 767px) {
      font-size: 20px;
    }

    &:last-child {
      background-color: #fff;
      color: #000000;
      font-size: 25px;
      font-weight: 300;
      white-space: normal;

      border-radius: 10px;
      padding: 15px 10px;
      margin-top: 30px;

      @media (max-width: 1365px) {
        font-size: 20px;
      }

      @media (max-width: 767px) {
        font-size: 18px;
      }
    }
  }

  @media (max-width: 1365px) {
    max-width: 490px;
    padding: 37px 54px;
  }

  @media (max-width: 767px) {
    max-width: 468px;
    padding: 18px 32px 40px;
  }
}
