.controls {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 50px;
}

.logout {
  margin-bottom: 10px;
  text-align: right;
}

.about {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 80px;

  @media(max-width: 767px) {
    margin-bottom: 40px;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -40px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(white, .5);

    @media(max-width: 767px) {
      display: none;
    }
  }


}

.orders {
  text-align: right;
}

.status {
  color: white;
  font-size: 33px;
  font-weight: 500;
  position: relative;

  @media (max-width: 1365px) {
    font-size: 28px;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    font-weight: 300;
  }
}

.info {
  display: flex;
  justify-content: space-between;

  color: white;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 50px;

  @media (max-width: 1365px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 91px;
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    row-gap: unset;
  }
}

.item {
  position: relative;

  &:not(:first-child) {
    &::before {
      display: none;
    }
  }

  @media (max-width: 767px) {
    padding-top: 10px;
    display: flex;

    &::after,
    &::before {
      content: '';
      position: absolute;
      height: 1px;
      width: 100%;
      background-color: #fff;
      left: 0;
    }

    &::before {
      top: 0;

    }

    &::after{
      bottom: 0;
    }
  }
}

.info_title {
  position: relative;
  margin-bottom: 15px;

  @media (max-width: 767px) {
    font-size: 14px;
    width: 90px;
    display: flex;
    align-items: center;

    &::after {
      content: '';
      display: block;
      width: 1px;
      height: 100%;
      background-color: #fff;
      position: absolute;
      right: 15px;
      top: 0;
    }
  }
}

.info_value {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 5px;
  display: inline-block;
  width: auto;

  @media (max-width: 767px) {
    font-size: 22px;
  }
}

.info_change {
  text-align: left;
  font-size: 14px;
  color:#fff;
  display: inline-block;
  width: auto;
  margin-left: 10px;
  padding: 10px 10px 14px;
  vertical-align: middle;

  &:hover {
    svg {
      path {
        fill: #2B3446;
      }
    }
  }

  & > svg {
    width: 10px;
    height: 10px;

    path {
      fill: white;
      transition: fill .3s ease;
    }
  }
}

.changePassword {
  text-align: center;
}
