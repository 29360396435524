.article {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  display: block;
  box-shadow: 5px 5px 8px 5px rgba(#000, 0.3);
  transition: box-shadow 0.3s ease;
  background-color: #e0e1e4;
  border-radius: 10px;
  overflow: hidden;
  height: 100%;

  display: flex;
  flex-flow: column nowrap;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &:hover {
    box-shadow: 5px 5px 8px 5px #45c6c2;
  }
}

.title {
  color: black;
  font-size: 20px;
  position: relative;
  margin-bottom: 10px;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
  }
}

.description {
  color: #00B1FF;
}