.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 35px;
  row-gap: 40px;

  @media (max-width: 1365px) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 30px;
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 20px;
  }
}

.current {
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 100px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: rgba(#fff, 1);
  }

  @media(max-width: 767px) {
    padding-bottom: 40px;
  }
}

.item {
  position: relative;
}

.quantity {
  position: absolute;
  top: 0;
  right: 0;
  width: min-content;
  white-space: nowrap;
  padding: 5px 5px 15px 15px;
  background-color: #e0e1e4;
  color: #00b1ff;
  border-bottom-left-radius: 50%;
}

.delivered {
}

.section {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 60px;
    padding-bottom: 50px;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: rgba(#fff, .3);
    }

    @media(max-width: 767px) {
      margin-bottom: 30px;
      padding-bottom: 20px;
    }
  }
}

.title_section {
  color: white;
  margin-bottom: 30px;
}

.date {
  color: white;
  margin-bottom: 15px;
}

.panel {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  & > button {
    white-space: nowrap;
  }

  @media (max-width: 1365px) {
    flex-direction: column-reverse;
    margin-bottom: 0;

    & > div {
      width: 100%;
    }

    & > ul {
      margin-bottom: 15px;
    }
  }
}

.filter {
  max-width: 600px;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    // max-width: 280px;
    width: 100%;

  }
}
