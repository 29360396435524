.wrapper {
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  & > button {
    &:last-child {
      align-self: flex-end;
    }
  }
}

.title {
  color: white;
  font-size: 20px;
  margin-bottom: 30px;
  text-align: center;
}

.fields {
  display: flex;
  flex-direction: column;
}

.field {
  &:first-child {
    margin-bottom: 25px;
  }

}

.required {
  color: white;
  font-weight: 300;
  margin-top: 20px;
  margin-bottom: 20px;
}

.success {
  color: white;
  text-align: center;
}