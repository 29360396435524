.list {
  display: grid;
  grid-template-columns: repeat(4, 90px);
  column-gap: 15px;
  width: min-content;
  border: 2px solid rgba(white, .5);
  border-radius: 10px;
  padding: 10px 0;

  @media (max-width: 767px) {
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
  }
}

.item {
  display: flex;
  flex-flow: column-reverse;
  justify-content: space-between;
  align-items: center;
  position: relative;
  color: rgba(white, .3);

  &:not(:last-child) {
    &::after {
      content: '→';
      position: absolute;
      top: 0; bottom: 0;
      right: -15px;
      margin: auto 0;
      height: 15px;

      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  &.white {
    color: white;
  }
}

.text {
  color: inherit;

  font-size: 11px;
  line-height: 1.3;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 11px;
  }
}

.icon {
  color: inherit;
  height: 25px;
  max-width: 25px;
  margin-bottom: 5px;

  @media (max-width: 767px) {
    height: 22px;
    max-width: 22px;
  }
}
