.title {
  margin-bottom: 16px;

  color: #fff;
  font-size: 25px;
  font-weight: 500;
  line-height: normal;
}

.items {
  display: flex;
  justify-content: space-between;

  .item {
    padding-right: 20px;

    &:last-child {
      padding-right: 0;
    }

    @media (max-width: 1365px) {
      padding-right: 0;
    }
  }

  @media (max-width: 1365px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 80px;
    row-gap: 30px;
  }

  @media (max-width: 767px) {
    column-gap: 28px;
    row-gap: 15px;
  }
}
