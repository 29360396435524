.mainBlock {
  margin-bottom: 40px;

  @media (max-width: 1365px) {
    margin-bottom: 23px;
  }
  @media (max-width: 767px) {
    margin-bottom: 30px;
  }
}

.video {
  width: 100%;
  aspect-ratio: 16 / 9;
  display: block;

  margin-bottom: 37px;

  @media (max-width: 1365px) {
    margin-bottom: 49px;
  }
  @media (max-width: 767px) {
    margin-bottom: 34px;
  }
}

.separator {
  width: 100%;
  height: 1px;

  margin-bottom: 50px;

  background-color: #fff;

  @media (max-width: 1365px) {
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    margin-bottom: 17px;
  }
}

.factory {
  margin-bottom: 30px;
}
