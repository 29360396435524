.consent {
  color: white;
  font-size: 16px;
  line-height: 1.3;
  padding-left: 20px;
  margin-top: 15px;
  display: inline;
  position: relative;
  z-index: 0;

  & > button {
    cursor: pointer;
    display: inline;
    width: min-content;
    white-space: nowrap;
    color: #00b1ff;
    font-size: 16px;
    border-bottom: 1px solid #00b1ff;

    @media(max-width: 767px) {
      font-size: 16px;
    }
  }

  @media(max-width: 767px) {
    font-size: 16px;
    margin-top: 10px;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2050;
  pointer-events: auto;
  display: flex;
  justify-content: center;
  padding: 50px 50px;

  @media(max-width: 1365px) {
    padding: 30px 30px;
  }

  @media (max-width: 767px) {
    width: 100vw;
    padding: 30px 15px;
  }
}

.content {
  position: relative;
  color: white;
  background-color: #2b3446;
  padding: 30px 70px;
  width: 1034px;
  border-radius: 15px;

  @media(max-width: 1365px) {
    box-shadow: 0 0 10px 0px rgba(white, .3);
  }

  @media (max-width: 767px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 50px;
  }
}

.text {
  font-weight: 300;
  padding-bottom: 20px;
  border-bottom: 1px solid white;
  height: 90%;
  overflow: auto;
  overscroll-behavior-y: contain;

  &::-webkit-scrollbar {
    width: 1px;
    border-radius: 3px;
    background-color: #2b3446;
  }

  &::-webkit-scrollbar-thumb {
    width: 1px;
    border-radius: 3px;
    background-color: #2b3446;
  }

  p {
    &:first-of-type {
      margin-top: 0;
    }
    margin-top: 20px;
    margin-bottom: 20px;
  }

  ul {
    padding-left: 20px;
    list-style-type: disc;

    li {
      margin-top: 5px;
      margin-bottom: 5px;
      line-height: 1.2;
    }
  }
}

.scrollBar {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid white;
  border-radius: 10px;
  width: 30px;
  height: 200px;
  top: calc(50% - 150px);
  right: 20px;

  img {
    width: 15px;
    height: 30px;
  }

  button:last-of-type {
    img {
      transform: rotate(180deg);
    }
  }
}

.buttons {
  margin-top: 35px;
  display: flex;
  justify-content: center;
  gap: 50px;
  padding-left: 30px;

  @media(max-width: 767px) {
    gap: 20px;
  }
}
