.price {
  font-size: 28px;
  line-height: 1.2;
  color: #00b1ff;
  display: flex;
  width: 100%;

  @media (max-width: 1365px) {
    margin-top: auto;
  }

  @media(max-width: 767px) {
    font-size: 22px;
  }

  &.wholesale {
    flex-direction: column;

    & > span {
      display: flex;

      & > span {
        &:nth-child(2) {
          text-align: right;
          padding-left: 10px;
        }
      }
    }
  }

  &.old {
    justify-content: space-between;

    & > span {
      position: relative;
      width: min-content;

      &:nth-child(2) {
        color: gray;

        &::before {
          content: '';
          position: absolute;
          right: -10%;
          top: 45%;
          width: 120%;
          height: 1px;
          background-color: gray;

          transform: rotate(-10deg);
        }
      }
    }
  }
}