.wrapper {
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  column-gap: 30px;

  @media (max-width: 767px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    flex-direction: column;
    row-gap: 30px;
  }
}

.productTitle {
  display: none;

  @media (max-width: 767px) {
    display: block;
    color: white;
    font-size: 16.24px;
    font-weight: 700;
  }
}

.image {
  width: 160px;
  min-width: 160px;
  height: 160px;
  box-shadow: 3px 3px 8px 5px rgba(#000, 0.3);
  overflow: hidden;
  border-radius: 10px;
  background-color: #fff;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
  }
}

.rest {
  display: grid;
  grid-template-columns: 3fr 3fr 2fr 1fr 1fr min-content;
  column-gap: 20px;
  padding-bottom: 20px;
  position: relative;

  &::before, &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #fff;
  }

  &::before {
    top: -10px;
  }

  &::after {
    top: 50px;
  }

  @media (max-width: 767px) {
    grid-column: 1/3;
    grid-template-columns: 3fr 3fr 2fr 1fr;
  }
}

.el {
  color: white;
  text-align: center;
  line-height: 1.5;

  & > p, & > div {
    padding-top: 40px;
    font-size: 14px;
    white-space: nowrap;
  }

  &::before {
    content: attr(data-text);
    font-size: 15px;
    font-weight: 300;
    text-align: center;

    @media (max-width: 767px) {
      font-size: 13px;
    }
  }

  @media (max-width: 1365px) {
    font-size: 14px;
  }
  @media (max-width: 767px) {
    font-size: 11px;
  }
}

.title {
  text-align-last: left;

  @media (max-width: 767px) {
    display: none;
  }
}

.articul {
  white-space: nowrap;

  @media (max-width: 1365px) {
    display: none;
  }
}

.price {
  .price_item {
    position: relative;
    font-size: 14px;

    &::after {
      content: attr(data-text);
      position: absolute;
      bottom: -18px;
      left: 0;right: 0;
      margin: 0 auto;

      font-size: 14px;
      font-weight: 300;
      white-space: nowrap;

      @media(max-width: 767px) {
        bottom: -20px;
      }
    }
  }
}

.bin {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-left: 10px;
  margin-top: 55px;
  padding: 5px;

  & > button {}

  svg {
    width: 20px;
    height: 25px;
  }
}