.wrapper {
    padding-right: 18px;
    padding-left: 18px;

    @media(max-width: 767px) {
        padding-left: 5px;
        padding-right: 5px;
    }
}
.container {
    position: relative;
    background-color: #E0E1E4;
    height: 350px;
    border-radius: 10px;
    overflow: hidden;

    @media (max-width: 1365px) {
        height: 400px;
    }

    @media (max-width: 767px) {
        height: 250px;
    }
}

.image {
    height: 190px;
    width: 100%;
    margin-bottom: 10px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media (max-width: 1365px) {
        height: 235px;
    }

    @media (max-width: 767px) {
        height: 125px;
    }
}

.title {
    color: #000;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.2;
    padding-left: 14px;
    padding-right: 30px;

    @media (max-width: 1365px) {
        font-size: 20px;
    }
    @media (max-width: 767px) {
        font-size: 13px;
    }

}
.readMore {
    position: absolute;
    bottom: 20px;
    left: 14px;

    @media (max-width: 767px) {
        width: min-content;
        left: 50%;
        transform: translateX(-50%);
    }
}
