.wrapper {
  display: grid;
  grid-template-columns: 3fr 5fr;
  column-gap: 50px;
  padding: 50px 0;
  position: relative;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  &:not(:last-child) {
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;

      height: 1px;
      background-color: #fff;
      opacity: 0.6;
    }
  }

  & > a {
    &:nth-child(2) {
      grid-area: 2/1/3/2;
      align-self: flex-end;

      @media (max-width: 767px) {
        // grid-column: 1/2;
        // grid-row: 2/3;
        // margin-bottom: 15px;
        display: none;
      }
    }
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.title {
  grid-area: 1/1/2/2;

  & > h3 {
    color: white;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.3;

    @media(max-width: 1365px) {
      font-size: 18px;
    }

    @media(max-width: 767px) {
      font-size: 17px;
    }
  }

  @media (max-width: 767px) {
    grid-row: 1/2;
    grid-column: 1/2;
    margin-bottom: 15px;
  }
}

.image {
  grid-area: 1/2/3/3;
  height: 290px;
  border-radius: 15px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    display: block;
  }

  @media (max-width: 1365px) {
    height: 220px;
  }

  @media (max-width: 767px) {
    grid-column: 1/2;
    grid-row: 3/4;
  }
}
