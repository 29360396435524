.promo {
  width: 100%;
  height: 100vh;

  @media (max-width: 767px) {
    height: 95vh;
  }
}

.info {
  & > div {
    & > section {
      position: relative;

      &:not(:first-child) {
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 1px;
          background-color: rgba(white, 0.6);
        }
      }
    }
  }
}

.video {
  width: 100%;
  aspect-ratio: 16 / 9;

  margin-bottom: 50px;
  margin-top: 50px;

  @media (max-width: 1365px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  @media (max-width: 767px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.about {
  padding-bottom: 50px;

  @media (max-width: 767px) {
    padding-bottom: 30px;
  }
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #fff;
}

.history {
  padding-top: 50px;
  padding-bottom: 50px;

  @media (max-width: 767px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.laboratory {
  padding-top: 50px;
  padding-bottom: 50px;

  @media (max-width: 767px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.projects {
  padding-top: 50px;
  padding-bottom: 50px;

  @media (max-width: 767px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.partners {
  padding-top: 50px;
  padding-bottom: 50px;

  @media (max-width: 767px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.news {
  padding-top: 50px;
  padding-bottom: 50px;

  @media (max-width: 767px) {
    padding-top: 30px;
    // margin-bottom: 30px;
    padding-bottom: 30px;
  }
}
