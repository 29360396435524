.wrapper {
    padding: 50px 7px 7px;
    border-radius: 15px;

    display: flex;
    flex-direction: column;
    max-width: 460px;

    & > button {
      margin-top: 30px;
    }

    @media (max-width: 767px) {
      max-width: 280px;
      padding-top: 30px;
    }
  }

  .field {
    min-width: 450px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
    @media (max-width: 767px) {
      max-width: 280px;
      min-width: unset;
    }
  }

  .title {
    margin-bottom: 20px;
    color: white;
    font-size: 20px;
    font-weight: 500;
    text-align: center;

    @media(max-width: 767px) {
      text-align: left;
      font-size: 16px;

    }
  }

  .agree {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  .required {
    color: white;
    font-weight: 300;
    margin-top: 15px;
    margin-bottom: 30px;

    @media(max-width: 767px) {
      margin-bottom: 0;
    }
  }

  .success {
    color: white;
    margin-top: 30px;
    text-align: center;

    background-color: #fff;
    border-radius: 15px;
    color: black;font-weight: 300;
    padding: 25px 20px;
  }
