.wrap {
  display: flex;
}

.button {
  // outline: 1px solid red;
  font-size: 25px;
  line-height: 0;
  // margin-bottom: 5px;
  // padding-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.increment {}

.decrement {}

.value {
  width: 5px;
  height: 4px;

  // background-color: #fff;
  margin-bottom: 3px;
  margin-right: 4px;
  color: white;
}