.title {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;

  @media(max-width: 767px) {
    font-size: 14px;
  }
}
