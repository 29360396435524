.wrapper {
  display: flex;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.contact {
  position: relative;
  width: min-content;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -39px;
    height: 100%;
    width: 1px;
    background-color: #fff;
  }

  &:first-child {
    &::after {
      display: none;
    }
  }

  @media (max-width: 1365px) {
    &::after {
      left: calc(-16vw + 115px);
    }
  }

  @media (max-width: 767px) {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 10px;
    border-left: unset;
    margin-bottom: 10px;

    &::after {
      display: none;
    }

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}
