.title {
  color: #1d1d1b;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
}

.background {
  display: block;
  position: absolute;
  width: 337px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 0px 20px 0px rgba(black, 0.2);
  padding: 10px 19px 20px 19px;

  z-index: 2;
  top: 50px;

  .fields {
    display: flex;
    gap: 30px;
  }
  .field {
    max-width: 150px;

    @media (max-width: 1365px) {
      max-width: 100%;
    }
  }

  @media (max-width: 1365px) {
    width: 100%;
  }
}
