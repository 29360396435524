.text {
  position: relative;

  display: block;
  width: 250px;

  background-color: #dc143c;
  padding: 15px;
  border-radius: 15px;

  color: white;
  font-weight: 500;
  font-size: 16px;

  &.success {
    color: #000;
    background-color: #72db7e;
  }
}
