.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 34px;

  @media(max-width: 1365px) {
    flex-direction: row;
  }
}
