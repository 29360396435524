.wrapper {
  padding: 50px 7px 10px;
  border-radius: 15px;

  display: flex;

  @media (max-width: 1365px) {
    flex-direction: column;
  }

  @media(max-width: 767px) {
    padding-top: 30px;
  }
}

.text {
  flex: 1 1 35%;
  color: white;
  font-weight: 300;
  margin-top: 40px;
  margin-right: 50px;
  min-width: 340px;

  & > dt {
    font-size: 20px;
    margin-bottom: 30px;

    @media(max-width: 767px) {
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 500;
    }
  }

  & > dd {
    font-size: 15px;
    line-height: 1.3;

    @media(max-width: 767px) {
      font-size: 14px;
    }
  }

  @media (max-width: 1365px) {
    margin-bottom: 40px;
  }

  @media (max-width: 767px) {
    max-width: 280px;
    min-width: unset;
    margin: 0 0 15px;
  }
}

.inner {
  flex: 1 1 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > button {
    margin-top: 30px;
  }
}

.title {
  color: white;
  margin-bottom: 20px;

  @media(max-width: 767px) {
    font-weight: 500;
  }
}

.fields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  row-gap: 20px;

  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.field {
  min-width: 250px;

  @media (max-width: 767px) {
    max-width: 280px;
  }
}

.agree {
  display: flex;
  align-items: center;
}

.required {
  color: white;
  font-weight: 300;
}
