.list {
  display: inline;
  list-style: none;
}

.inner {
  width: auto;
  display: inline;
}

.item {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  line-height: normal;

  .separator {
    font-size: 14px;
    padding: 0 7px;
  }

  &.blue {
    color: #00b1ff;
  }
}
