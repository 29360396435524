.additional_info {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .print {
    margin-left: auto;
    width: auto;
    font-family: 'Stem';
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    margin-right: 60px;

    @media (max-width: 1365px) {
      font-size: 14px;
      margin-right: 40px;
    }
    @media (max-width: 767px) {
      margin-right: 30px;
    }
  }

  .share {
    width: auto;
    margin-right: 20px;

    color: #fff;
    font-size: 16px;
    font-weight: 500;
    height: 18px;

    @media (max-width: 1365px) {
      font-size: 14px;
    }
  }

  .shareSocial {
    width: auto;

    & > a {
      display: block;

      & > svg {
        max-width: 30px;
        max-height: 35px;
      }
    }
  }
