.title {
  @media(max-width: 1365px) {
    margin-bottom: 40px !important;
  }

  @media(max-width: 767px) {
    margin-bottom: 20px !important;
  }
}

.images {
  margin-bottom: 90px;

  @media (max-width: 1365px) {
    margin-bottom: 48px;
  }

  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    display: none;
  }
}

.description {
  margin-top: 64px;
  margin-bottom: 138px;
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.3;
  white-space: pre-wrap;

  display: flex;
  flex-direction: column;

  @media (max-width: 1365px) {
    font-size: 16px;
    margin-bottom: 64px;
    margin-top: 0;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    margin-bottom: 44px;
  }

  & > h2 {
    font-weight: 300 !important;
  }

  & > h3 {
    margin: 10px 0;
  }

  & > ul, ol {
    line-height: 0;
    margin: 7px 0;

    & > li {
      padding-left: 20px;
      position: relative;
      margin-bottom: 7px;
      line-height: 1.3rem;
      white-space: pre-line;

      @media (max-width: 767px) {
        line-height: 1.1;
      }

      & > strong {
        margin-bottom: 5px;
        display: inline-block;
      }

      &::before {
        content: '●';
        position: absolute;
        left: 0px;
        top: calc(.5rem - 8px);
        font-size: 10px;

        @media (max-width: 1365px) {
          font-size: 7px;
          top: calc(.5rem - 9px);
        }

        @media (max-width: 767px) {
          top: calc(.5rem - 6px);
        }
      }

      & > br {
        &:first-of-type {
          display: none;
        }
      }
    }
  }
}
