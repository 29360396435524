// .filials {}

.map {
  @media (max-width: 1365px) {
    height: 233px;
  }
  @media (max-width: 767px) {
    height: 142px;
    height: 280px;
  }
}

.control {
  padding: 50px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.myClassName {
  background-color: transparent;
  width: auto;
  min-width: 300px;

  @media (max-width: 1365px) {
    min-width: 148px;
    margin-right: 10px;
  }
}

.myControlClassName {
  background-color: transparent !important;
  border-radius: 30px !important;
  padding: 10px 30px !important;
}

.myPlaceholderClassName {
  font-family: Stem;
  color: white;
}

.myMenuClassName {
  border-radius: 30px;
  opacity: .9;
  padding: 10px 15px;

  & > div {
    padding: 10px 15px;
    background-color: white;
    transition: background-color .3s ease;
    color: #1D1D1B;

    &:hover {
      background-color: #efefef;
    }
  }
}

.BlueButton {
  width: min-content;
}

.items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;

  @media (max-width: 1365px) {
    grid-template-columns: 1fr;
  }
}

.item {
  display: flex;
  gap: 35px;

  @media (max-width: 1365px) {
    padding-bottom: 75px;
    position: relative;
    gap: 15px;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #fff;
    }
  }

  @media(max-width: 767px) {
    padding-bottom: 40px;
    gap: 10px;
  }
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;

  @media(max-width: 767px) {
    gap: 7px;
  }
}

.image {
  flex: 1 0 270px;
  width: 270px;
  height: 270px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  @media (max-width: 767px) {
    flex: 1 0 130px;
    width: 130px;
    height: 130px;
  }
}

.title {
  font-weight: 500;
  color: white;
}

.address {
  color: white;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.3;
}

.phone {
  color: white;
  font-weight: 300;

  & > a {
    cursor: pointer;
    color: white;
  }
}

.email {
  color: white;
  font-weight: 300;
}
