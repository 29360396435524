.image {
  width: 100%;
  aspect-ratio: 1 / 1;

  overflow: hidden;
  border-radius: 17px;

  margin-bottom: 20px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media(max-width: 767px) {
    margin-bottom: 11px;
  }
}

.title {
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;

  @media (max-width: 1365px) {
    font-size: 18px;
    font-weight: 500;
  }

  @media (max-width: 767px) {
    font-size: 16px;
  }
}
