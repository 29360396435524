.wrapper {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 100;
  width: 62px;
  height: 62px;
  transform: rotate(90deg);

  backdrop-filter: blur(2px);
  border-radius: 50%;
  background-color: transparentize(#000, 0.92);
}
