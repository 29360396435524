.wrapper {
  display: flex;
  justify-content: space-between;
  column-gap: 100px;

  @media (max-width: 1365px) {
    flex-direction: column;
  }
}

.title {
  @media (max-width: 1365px) {
    font-size: 36px;
    margin-bottom: 10px;
  }

  @media (max-width: 767px) {
    font-size: 24px;
    margin-bottom: 6px;
  }
}

.sectionCooperation {
  @media (max-width: 1365px) {
    margin-bottom: 33px;
  }

  @media (max-width: 767px) {
    margin-bottom: 22px;
  }
}

.subtitle {
  color: #fff;
  font-size: 21px;
  font-weight: 500;

  @media (max-width: 767px) {
    font-size: 18px;
  }
}

.sectionWrapper {
  display: flex;
  flex-direction: column;
}

.advantages {
  display: grid;
  grid-template-columns: 80px auto;
  column-gap: 80px;

  padding: 40px 0;
  position: relative;

  @media (max-width: 1365px) {
    column-gap: 60px;
  }

  @media (max-width: 767px) {
    column-gap: 20px;
    padding: 20px 0;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #fff;
  }

  &:last-child {
    &::after {
      display: none;

      @media (max-width: 1365px) {
        display: block;
      }
    }
  }
}

.advantagesImage {
  grid-area: 1/1/3/2;
  align-self: center;

  @media (max-width: 1365px) {
    & > svg {
      width: 100%;
    }
  }
}

.advantagesTitle {
  grid-area: 1/2/2/3;

  color: white;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 12px;

  @media (max-width: 1365px) {
    font-size: 23px;
    font-weight: 500;
  }

  @media (max-width: 767px) {
    font-size: 15px;
  }
}

.advantagesList {
  grid-area: 2/2/3/3;

  & > li {
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.3;
    margin-bottom: 8px;

    @media (max-width: 1365px) {
      font-size: 18px;
    }

    @media (max-width: 767px) {
      font-size: 14px;
      margin-bottom: 4px;
    }
  }
}

.sectionDillers {
  .title {
    @media (max-width: 1365px) {
      margin-bottom: 40px;
    }

    @media (max-width: 767px) {
      margin-bottom: 8px;
    }
  }
}

.sertificat {
  padding: 0 10px 0;

  &:last-child {
    padding-right: 0;
  }

  @media (max-width: 767px) {
    padding: 0;
  }
}

.slider {
  width: 100%;
  max-width: 400px;

  @media (max-width: 1365px) {
    max-width: unset;
  }
}

.sertButton {
  & > p {
    color: #fff;
  }

  & > img {
    display: block;
    margin-bottom: 10px;
    width: 100%;
    height: 100%;
  }
}

.toStayPartner {
  display: flex;
  flex-direction: column;

  & > p {
    color: #fff;
    font-size: 21px;
    font-weight: 500;

    margin-bottom: 30px;
    margin-top: 30px;

    @media (max-width: 1365px) {
      font-size: 36px;
    }

    @media (max-width: 767px) {
      font-size: 18px;
      font-weight: 300;
    }
  }

  & > a {
    width: min-content;
    margin-bottom: 30px;
  }

  & > span {
    color: white;
    font-size: 14px;
    font-weight: 300;
  }
}

.window_photo {
  width: 100%;
  overflow: hidden;
  position: relative;

  img {
    display: block;
    width: 100%;
    height: 100%;
    max-height: 90vh;
    object-fit: contain;

    @media (max-width: 1365px) {
      max-width: 80vw;
    }

    @media (max-width: 767px) {
      max-width: 60vw;
    }
  }
}

.window_info {
  position: absolute;
  bottom: 0;
  z-index: 3000;
  color: white;
  text-align: center;
  padding: 10px 0;
}

.next,
.prev {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  width: min-content;
}

.next {
  right: 40px;

  @media (max-width: 1365px) {
    right: 10px;
    width: 40px;
    svg {
      width: 100%;
    }
  }
}

.prev {
  left: 30px;

  @media (max-width: 1365px) {
    left: 10px;
    width: 40px;
    svg {
      width: 100%;
    }
  }
}

.modal {
  img {
    width: 80vw;
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.inner {
  margin-top: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  position: relative;

  &::before {
    content: '›';
    position: absolute;
    font-size: 24px;
    line-height: 0.5;
    color: white;
    left: 0px;
    top: 12px;
    transition: transform 0.3s ease;
    transform-origin: 50% 70%;
  }

  &.open {
    &::before {
      transform: rotate(90deg);
    }
  }

  .innerTitle {
    font-size: 24px;
    color: white;
    font-weight: 500;
    text-align: left;
    margin-bottom: 10px;
    padding-left: 30px;
    position: relative;
    left: -30px;
  }
}

.categoriesContainer {
  overflow: hidden;
  transition: height 0.3s ease;
}

.categoriesWrapper {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
}

.categoriesTitle {
  color: white;
  font-weight: 500;
  margin-bottom: 10px;
}

.certificateWrapper {
  margin-bottom: 20px;
}

.certItem {
  display: flex;
  margin-bottom: 7px;
  padding-left: 10px;

  .certTitle {
    width: auto;
    line-height: 1.3;
  }

  .certFile {
    width: auto;
    display: flex;
    color: white;
    font-weight: 300;

    &:visited {
      color: hsl(199, 94%, 80%);
    }

    .title {
    }
  }
}

.icon {
  color: inherit;
  width: 16px;
  height: 16px;
  margin-right: 7px;
  margin-bottom: 2px;
}
