.wrapper {
  display: flex;
  justify-content: flex-end;

  padding-left: 50px;
  padding-top: 47px !important;
  padding-bottom: 94px !important;
  padding-right: 50px;

  background-color: rgba(43, 52, 70, 0.95);
  border-radius: 8px;
  box-shadow: 0px 0px 8px 0px rgba(white, 0.5);
  z-index: 100;
  max-width: min-content;

  @media(max-width: 1365px) {
    padding: 50px !important;
  }

  @media(max-width: 767px) {
    padding: 40px !important;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 130px !important;
  }
}

.links {
  display: flex;
  flex-direction: column;
  gap: 5px;

  width: min-content;

  & > a {
    color: #fff;
    font-size: 28px;
    font-weight: 500;
    line-height: normal;

    @media(max-width: 767px) {
      padding: 15px 0;
      line-height: 1;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: rgba(#fff, .5);
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }

  @media(max-width: 767px) {
    gap: 0;
  }

  .pressed {
    color: red;
  }
}

.catalog {
  width: 228px;
  margin-right: 75px;

  @media(max-width: 767px) {
    margin-right: 0;
    width: auto;
  }

  .categories {
    @media(max-width: 767px) {
      overflow: hidden;
      transition: height .3s ease;
      display: flex;
      flex-direction: column;
    }

    .categories_list {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .category {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: normal;

      @media(max-width: 767px) {
        &:first-child {
          padding-top: 20px;
        }
      }
    }
  }
}

.info,
.catalog {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .blockTitle {
    margin-bottom: -4px;
    color: #fff;
    font-size: 28px;
    font-weight: 500;
    line-height: normal;

    &.button {
      pointer-events: none;
      position: relative;

      &::before {
        content: '›';
        display: none;
        position: absolute;
        font-size: 20px;
        line-height: 0;
        color: white;
        left: -10px;
        top: 18px;
        transition: transform .3s ease;
      }

      &.open {
        &::before {
          transform: rotate(90deg);
        }
      }

      @media(max-width: 767px) {
        pointer-events: all;

        &::before {
          display: block;
          top: 13px;
        }
      }
    }

    & > h3 {
      text-align: left;
      font-weight: 500;
    }
  }

  a {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
  }

  @media(max-width: 767px) {
    gap: 0;
    width: 100%;
    padding: 15px 0 19px 0;
    position: relative;

    h3 {
      line-height: 1;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: rgba(#fff, .5);
    }
  }
}

.info {
  width: 137px;
  margin-right: 67px;

  @media(max-width: 767px) {
    margin-right: 0;
    width: 100%;
  }
}

.infoWrapper {
  @media(max-width: 767px) {
    height: 0;
    overflow: hidden;
    transition: height .3s ease;

    &_open {
      height: auto;

      & > a {
        padding-left: 15px;
      }
    }
  }
}

.infoList {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media(max-width: 767px) {
    padding-top: 20px;
  }

  .pressed {
    background-color: red;
  }

  & > a {
    font-size: 16px;
  }
}

.contacts {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 69px;
  right: 67px;
  width: min-content;
  white-space: nowrap;

  @media(max-width: 767px) {
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 50px;
  }

  .phoneIcon {
    position: absolute;
    top: -10px;
    left: -30px;
    width: 21px;
    height: 21px;

    @media(max-width: 767px) {
      display: none;
    }
  }
  .phone {
    margin-bottom: 10px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 0%; /* 0px */

    @media(max-width: 767px) {
      margin-bottom: 20px;
    }
  }
  .email {
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    line-height: 140%; /* 19.6px */
  }
}
